<template>
  <div class="min-table" v-loading="table.loading">
    <!-- <Spin v-if="table.loading" size="large" fix /> -->
    <div v-if="getMenu.right.length != 0 || getMenu.top != 0 || page" class="table-top-button-wrap">
      <ElTooltip
        v-for="item in getIconMenuLeft"
        :key="item.id"
        class="item"
        effect="dark"
        :content="item.name"
        placement="top-start"
      >
        <ElButton
          v-if="['exportExcel','exportExcelHttp','exportCustom'].includes(item.functionName)"
          icon="_icon-upload"
          class="padding6 "
          @click="topFun(item)"
        />

        <ElButton
          v-else-if="['del', 'delete','delModal'].includes(item.functionName)"
          :key="item.id"
          icon="_icon-ashbin"
          class="padding6 buttonDel"
          @click="topFun(item)"
        />
      </ElTooltip>
      <ElTooltip
        class="item"
        effect="dark"
        content="刷新"
        placement="top-start"
      >
        <ElButton icon="_icon-exchangerate" class="padding6 refresh" @click="refresh" />
      </ElTooltip>

      <ElButton
        v-for="item in getNameMenuLeft"
        :key="item.id"
        style="margin-left:8px"
        :class="getClass(item)"
        :type="getButtonType(item, 'top')"
        :ghost="getButtonGhost(item)"
        @click="topFun(item)"
      >
        {{ item.name }}
      </ElButton>
      <ElDropdown v-if="dropdownMenuItems.length">
        <ElButton :type="getButtonType({}, 'top')" class="ml-1">
          {{ dropdownLeftInfo.dropdownName }}<i class="el-icon-arrow-down el-icon--right" />
        </ElButton>
        <ElDropdownMenu slot="dropdown">
          <ElDropdownItem v-for="(item , index ) in dropdownMenuItems" :key="index" @click.native="topFun(item)">
            {{ item.name }}
          </ElDropdownItem>
        </ElDropdownMenu>
      </ElDropdown>

      <div class="flex1">
        <ColumnSetting
          style="margin-left: 8px;float: right;"
          v-if="setting===1"
          :columns="getTitle"
          :activeName="activeName"
          :columnsInitNotSelected="columnsInitNotSelect"
          @confirm="confirm"
        />
        <template v-for="(item,n) in getMenuRight">
          <span
            v-if="item.buttonType=='radio'"
            :key="item.id"
            style="min-width:90px;margin-left: 8px;float: right;"
          >
            {{ item.name }}：
            <ElRadioGroup
              v-model="item.value"
              @change="topFun(item)"
            >
              <ElRadio v-for="i in item.optionList" :key="'optionList'+i.id" :label="i.id">
                {{ i.name }}
              </ElRadio>
            </ElRadioGroup>
          </span>
          <ElButton
            v-else
            :key="item.id +''"
            :class="getClass(item,'right',n)"
            :type="getButtonType(item, 'right',n)"
            :ghost="getButtonGhost(item)"
            style="min-width:90px;margin-left: 8px;float: right;"
            @click="topFun(item)"
          >
            {{ item.name }}
          </ElButton>
        </template>
        <ElButton
          v-if="getMenuIf('right', {}).length >3"
          style="min-width:90px;margin-left: 8px;float: right;"
          @click="showMore = !showMore"
        >
          更多
          <i :class="!showMore ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" />
        </ElButton> 
      </div>
    </div>
    <ElTable
      ref="minTableIndex"
      :data="getData"
      :border="tableBorder"
      style="width: 100%"
      size="small"
      :key="isUpdate"
      :max-height="getHeight"
      :show-summary="summary"
      :row-key="rowKey"
      :summary-method="handleSummary"
      :header-cell-style="() => {return 'background-color: #f8f8f9;color: #515a6e;'}"
      :cell-style="() => {return 'padding: 4px 0;line-height: 16px'}"
      :row-style="() => {return 'line-height: 16px'}"
      class="set-table-border"
      :row-class-name="isActiveRow"
      @selection-change="setSelection"
      @sort-change="setCustom"
      @header-dragend="dragend"
    >
      <ElTableColumn
        v-if="showIndex"
        label="序号"
        type="index"
        fixed="left"
        align="center"
        width="60"
        class-name="tableShowIndex"
        :resizable="false"
      />
      <ElTableColumn
        v-if="selection && (getMenuIf('top', {}).length || getMenuIf('right', {}).length)"
        key="Math.random()"
        type="selection"
        :reserve-selection="reserveSelection"
        fixed="left"
        width="55"
        :resizable="false"
      />
      <ElTableColumn
        v-if="getMenu.table.length"
        fixed="left"
        label="操作"
        :resizable="false"
        :width="value.actionWidth ? value.actionWidth : 100"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in getMenuIf('table', scope.row, 3, true , true)"
            :key="index"
            class="table-column-button"
            @click="tableFun(item, scope.row)"
          >
            {{ item.name }}
          </div>
          <div
            v-if="getMenuIf('table', scope.row).length > 4"
            class="table-column-button"
            style="margin-right: 0;text-decoration: none;"
            @click="buttonModal = true; buttonObj = scope.row"
          >
            更多
          </div>
        </template>
      </ElTableColumn>
      <ElTableColumn
          v-for="(item, index) in tableColumns"
          :key="tableIndex?index+'key'+tableIndex:index"
          :prop="item.prop"
          :align="item.align ? item.align : 'left'"
          :label="item.label"
          :sortable="item.sortable==true"
          :sort-method="item.sortMethod"
          :resizable="index==(tableColumns.length-1)?false:true"
          :min-width="item.minWidth"
          :show-overflow-tooltip="item.showoverflowtooltip==false?false:true"
        >
          <template slot-scope="scope">
            <Expand
              v-if="item.render"
              :row="scope.row"
              :column="item"
              :index="scope.$index"
              :render="item.render"
            />
            {{ item.render ? '' : scope.row[item.prop] || scope.row[item.prop] === 0 ? scope.row[item.prop] : "-" }}
          </template>
      </ElTableColumn>
    </ElTable>
    <div v-if="page" :class="`table-page bottom-fixed-div ${state ? 'table-page-absolute' : ''}`">
      <div class="total-right">
        当前共选中 {{ table.selectionTotal }} 条
      </div>
      <div class="total">
        共查询到 {{ table.actualTotal || table.total }} 条
      </div>
      <ElPagination
        v-if="page"
        background
        :current-page.sync="table.searchData.pageNo"
        :page-sizes="table.pageSizeOpts"
        :page-size="table.searchData.pageSize"
        layout="prev, pager, next,sizes, jumper"
        :total="table.total"
        @size-change="changePageSize"
        @current-change="changePage"
      />
    </div>
    <ElDialog
      :visible.sync="buttonModal"
      title="操作按钮"
      width="550px"
      :close-on-click-modal="false"
      :before-close="modalChange"
    >
      <ElButton
        v-for="(item, index) in getMenuIf('table', buttonObj)"
        :key="index"
        style="min-width:90px;margin: 0 8px 8px 0;"
        @click="tableFun(item, buttonObj)"
      >
        {{ item.name }}
      </ElButton>
    </ElDialog>
    <ElDialog
      :visible.sync="exports.modal"
      :title="exports.state ? '高级导出' : '导出'"
      :close-on-click-modal="false"
      width="520px"
    >
      <div>
        文件标题：<ElInput
          v-model.trim="exportName"
          maxlength="18"
          placeholder="请填写文件标题"
          style="margin: 8px 0px; width: 417px;"
          @blur="filerValue"
        />
      </div>
      <div v-if="exports.state">
        <template v-if="isAsyncAdvancedExport">
          <ZAlert
            class="margin-bottom-10"
            info="每个表格最多支持50000条数据，多余50000条数据将分为多个表格导出数据请在【数据中心-导出历史】中查看"
            type="success"
          />
        </template>
        <template v-else>
          <ZAlert
            class="margin-bottom-10"
            info="每次最少导出 10 条， 最多导出 1000 条， 每次导出条数可在下方 '每次导出条数' 修改。"
            type="success"
          />
          <ElPagination
            :current-page.sync="exports.pageNo"
            :page-size="exports.pageSize ? exports.pageSize : 1"
            :total="table.total"
            layout="prev, pager, next"
          />
          <div style="margin: 10px 0px">
            每次导出条数：
            <ElInputNumber
              v-model.trim="exports.pageSize"
              :max="1000"
              :min="10"
              :precision="0"
              @change="exports.pageNo = 1"
            />
          </div>
        </template>
      </div>
      <div slot="footer">
        <div v-if="!exports.state">
          <ElButton
            v-if="exportFileName === 'exportExcel'"
            icon="el-icon-download"
            @click="exports.state = true"
          >
            高级导出&nbsp;
          </ElButton>
          <ElButton
            v-else
            icon="el-icon-download"
            type="primary"
            @click="exportFile"
          >
            {{ exportFileBtnName }}
          </ElButton> &nbsp;
          <ElButton icon="el-icon-download" type="primary" @click="exportExcel(table.selection)">
            导出表格所选数据
          </ElButton>
        </div>
        <ElButton
          v-else-if="isAsyncAdvancedExport"
          icon="el-icon-download"
          type="primary"
          @click="asyncAdvancedExport"
        >
          {{ asyncAdvancedExportText }}
        </ElButton>
        <ElButton
          v-else
          icon="el-icon-download"
          type="primary"
          @click="getTableValueExport(table.total / exports.pageSize <= 1 ? table.total : `${(exports.pageNo - 1) * exports.pageSize} - ${exports.pageNo == Math.ceil(table.total / exports.pageSize) ? table.total : exports.pageNo * exports.pageSize}`)"
        >
          导出
          {{
            table.total / exports.pageSize <= 1 ? table.total : `${(exports.pageNo - 1) *
              exports.pageSize} -
                    ${exports.pageNo == Math.ceil(table.total / exports.pageSize) ? table.total : exports.pageNo *
            exports.pageSize}`
          }}
          条
        </ElButton>
      </div>
      <Spin v-if="exports.loading" size="large" fix />
    </ElDialog>
    <ElDialog
      :visible.sync="exports.modalMin"
      title="导出"
      :close-on-click-modal="false"
      width="520px"
      append-to-body
    >
      <div>
        文件标题：<ElInput
          v-model.trim="exportName"
          :maxlength="defaultExportName?30:18"
          placeholder="请填写文件标题"
          style="margin: 8px 0px; width: 417px;"
          @blur="filerValue"
        />
      </div>
      <div slot="footer">
        <ElButton
          v-if="downLoadByParent"
          icon="el-icon-download"
          type="primary"
          @click="exportExcel(table.selection)"
        >
          导出表格所选数据
        </ElButton>
        <ElButton icon="el-icon-download" type="primary" @click="exportExcel(table.list,1)">
          导出列表数据
        </ElButton>
      </div>
      <Spin v-if="exports.loading" size="large" fix />
    </ElDialog>
  </div>
</template>
<script>
import excel from '../../config/excel'
import NP from 'number-precision'
import Expand from './expand'
import ColumnSetting from './ColumnSetting.vue'

export default {
  components: {
    Expand,ColumnSetting
  },
  props: {
    rowKey: {
      type: String,
      default: 'id',
    },
    value: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    exportFileName: {
      type: String,
      default: 'exportExcel',
    },
    search: {
      type: Boolean,
      default: true,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
    showExportId: {
      type: Boolean,
      default: false,
    },
    showExportIndex: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    actualTotal: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: Object,
      default: () => {
        return {}
      },
    },
    searchValueNew: {
      type: Object,
      default: () => {
        return {}
      },
    },
    exportMax: {
      type: String,
      default: '',
    },
    page: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Boolean,
      default: true,
    },
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    code: {
      type: [String, Number],
      default: 'auto',
    },
    authorityType: {
      type: String,
      default: 'auto',
    },
    userVariable: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Boolean,
      default: false,
    },
    userAdminVariable: {
      type: Boolean,
      default: false,
    },
    // 是否自动查询 默认自动
    loadState: {
      type: Boolean,
      default: true,
    },
    // 是否开启显示动态过滤
    titleFiler: {
      type: Boolean,
      default: false,
    },
    // 是否开启角色自身字段显示动态过滤
    titleIFiler: {
      type: Boolean,
      default: false,
    },
    delValueFiler: {
      type: Function,
      default: (val) => {
        return val.map(item => item.id).join()
      },
    },
    // 是否给定高度
    maxHeight: {
      type: Number,
      default: 0,
    },
    // 对话框需要设置ture 两层
    parent: {
      type: Boolean,
      default: false,
    },
    downLoadByParent: {
      type: Boolean,
      default: false,
    },
    defaultExportName: {
      type: String,
      default: '',
    },
    tableIndex: {
      type: Number,
      default: 0,
    },
    // 是否是异步高级导出
    isAsyncAdvancedExport: {
      type: Boolean,
      default: false,
    },
    // 异步高级导出url
    asyncAdvancedExportUrl: {
      type: String,
      default: '',
    },
    // 异步高级导出参数
    asyncAdvancedExportParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    tableBorder: {//表格出现边框
      type: Boolean,
      default: true,
    },
    setting:{//表头设置按钮显示
      type: Number,
      default: 1,
    },
    activeName: {
      type: [String, Number],
      default: '',
    },
    exportFileBtnName: {
      type: String,
      default: '导出列表数据',
    },
    columnsInitNotSelect: {
      type: Array,
      default: () => {
        return []
      },
    },
    colomnHidden: {
      type: Boolean,
      default:false,
    },
    dropdownLeftInfo: {
      type: Object,
      default: () => {
        return {
          dropdownName:'',
          dropdownItems:[]
        }
      },
    },
    

  },
  data() {
    return {
      exportName: '',
      state: false,
      exportModal: false,
      buttonModal: false,
      exportloading: false,
      buttonObj: {},
      exports: {
        state: false,
        modal: false,
        modalMin: false,
        loading: false,
        titleName: '导出数据',
        pageNo: 1,
        pageSize: 1000,
      },
      table: {
        loading: false,
        selection: [],
        selectionTotal: 0,
        list: [],
        selectedRows: [],
        total: 0,
        actualTotal: 0,
        pageSizeOpts: [10, 30, 50, 70, 100], //每页条数切换的配置
        searchData: { pageNo: 1, pageSize: 30 },
      },
      showMore: false, //右侧按钮展示更多标志,
      showTableActions: true,
      tableColumns:[],
      isUpdate:true
    }
  },
   watch: {
    getTitle:{
      handler(newVal) {
        this.tableColumns = newVal;
      },
      immediate: true,
      
    },
  },
  computed: {
    getData() {
      const value = this.value
      if (value.http === false) return value.data
      return this.table.list
    },
    getTitleSelect() {
      let list = []
      const defaultTitle = {
        platform: [],
        madicine: [],
        agent: [],
        adminPlatform: [],
        adminMadicine: [],
        adminAgent: [],
        public: [],
      }
      const title = { ...defaultTitle, ...this.value.title }
      const { platform, madicine, agent, adminPlatform, adminMadicine, adminAgent } = title
      if (this.userVariable === false) {
        return Object.assign([], this.value.title)
      }
      const { userSourceType, adminFlag } = this.store.getters.get_user
      if (userSourceType == 0) {
        // 平台 platform
        if (adminFlag && this.userAdminVariable) {
          list = adminPlatform
        } else {
          list = platform
        }
      } else if (userSourceType == 1) {
        // 服务商 madicineAgent
        // if (adminFlag && this.userAdminVariable) list = adminMadicineAgent
        // list = madicineAgent
      } else if (userSourceType == 3) {
        // 药企 madicine
        if (adminFlag && this.userAdminVariable) {
          list = adminMadicine
        } else {
          list = madicine
        }
      } else if (userSourceType == 4) {
        // 团队 agent
        if (adminFlag && this.userAdminVariable) {
          list = adminAgent
        } else {
          list = agent
        }
      } else if (userSourceType == 5) {
        // 隐形超管 superman
        if (adminFlag && this.userAdminVariable) {
          list = Object.assign(platform, madicine, agent, adminPlatform, adminMadicine, adminAgent)
        } else {
          list = Object.assign(platform, madicine, agent)
        }
        const all = []
        list.forEach(x => {
          if (!this.rule.getArrayIsValue(all.map(item => item.prop), x.prop)) {
            all.push(x)
          }
        })
        list = all
      }
      if (!list || list.length === 0) {
        return title.public
      }
      return list
    },
    getTitle() {
      const titleList = this.getTitleSelect
      if (this.titleFiler) {
        return [].concat(this.getTitleFiler(titleList))

      } else {
        for (let item = titleList.length; item--;) {
          if (titleList[item].filer && titleList[item].render === undefined) {
            titleList[item].render = (h, params) => {
              return h('div', {
                attrs: { class: titleList[item].class ? `${titleList[item].class(params.row[titleList[item].prop])} ` : '' },
              }, titleList[item].filer(params.row[titleList[item].prop], params.row, params.index))
            }
          }
        }
        return [].concat(titleList)
      }
    },
    getTitleExport() {
      return [].concat(this.getTitle)
    },
    getMenu() {
      const value = this.value
      let dynamicOperation = this.store.getters.get_menuSelect.meta
      const defaultValue = {
        search: false,
        top: [],
        right: [],
        table: [],
        list: [],
      }
      let operation = JSON.parse(JSON.stringify(defaultValue))
      if (value.operation && this.authorityType !== 'auto') {
        operation.search = value.operation.search || false
        operation.table = value.operation.table || []
        operation.top = value.operation.top || []
        operation.right = value.operation.right || []
      }

      dynamicOperation = dynamicOperation.authority || JSON.parse(JSON.stringify(defaultValue))
      switch (this.authorityType) {
      case 'operation':
        dynamicOperation = operation
        break
      case 'mesh':
        if (this.code !== 'auto') {
          dynamicOperation = dynamicOperation[this.code] || JSON.parse(JSON.stringify(defaultValue))
        }
        // eslint-disable-next-line no-case-declarations
        const mesh = {
          search: operation.search || dynamicOperation.search,
          top: [...operation.top, ...dynamicOperation.top],
          right: [...operation.right, ...dynamicOperation.right],
          table: [...operation.table, ...dynamicOperation.table],
          list: [...operation.list, ...dynamicOperation.list],
        }
        dynamicOperation = mesh
        break
        // case 'auto':
        //     dynamicOperation = dynamicOperation[this.code] || dynamicOperation
      default:
        dynamicOperation = dynamicOperation[this.code] || dynamicOperation
        break
      }
      return dynamicOperation
    },
    getHeight() {
      return this.maxHeight === 0 ? this.store.getters.get_height.max - 80 : this.maxHeight
    },
    getMenuRight() {
      //初始化展示3个按钮
      const getMenuRights = this.getMenuIf('right', {}) || []
      let finalData = getMenuRights
      if (getMenuRights.length > 2 && !this.showMore) {
        finalData = getMenuRights.slice(0, 3)
      }

      return finalData
    },
    getIconMenuLeft() {
      const getMenuLefts = this.getMenuIf('top', {}) || []
      return getMenuLefts.filter(i => ['del', 'delModal', 'delete', 'exportExcel', 'exportExcelHttp', 'exportCustom'].includes(i.functionName))
    },
    getNameMenuLeft() {
      const getMenuDels = this.getMenuIf('top', {}) || []
      let list = ['del', 'delModal', 'delete', 'exportExcel', 'exportExcelHttp', 'exportCustom']
      const {dropdownItems=[]}=this.dropdownLeftInfo
      if(dropdownItems.length){ 
        const items= dropdownItems.filter(i => getMenuDels.some(x => x.functionName === i.functionName))
        const itemKey = items.map(x=>x.functionName)
        list.push(...itemKey)
      }
      return getMenuDels.filter(i => !list.includes(i.functionName))
    },
    dropdownMenuItems() {
      const getMenuLefts = this.getMenuIf('top', {}) || []
      const {dropdownItems=[]}=this.dropdownLeftInfo
      let items=[]
      if(dropdownItems.length){ 
         items= dropdownItems.filter(i => getMenuLefts.some(x => x.functionName === i.functionName))
      }
      return items
    },
    asyncAdvancedExportText() {
      const total = this.table.total
      const fileNum = Math.ceil(total / 50000)
      return `导出${total}条数据，分${fileNum}个文件`
    },
  },
  beforeDestroy() {
    this.state = false
    this.exportModal = false
    this.buttonModal = false
    this.exportloading = false
    this.buttonObj = false
    this.exports = {
      state: false,
      modal: false,
      loading: false,
      pageNo: 1,
      pageSize: 1000,
    }
    this.table = {
      loading: false,
      selection: [],
      selectionTotal: 0,
      list: [],
      selectedRows: [],
      total: 0,
      searchData: { pageNo: 1, pageSize: 30 },
    }
  },
  mounted() {
    if (this.loadState) {
      this.getTableValue()
    }
  },
  methods: {
    getTitleFiler(titleList) {
      const titlePass = []
      const titleFail = this.value.titleFail ? this.value.titleFail : []
      if (this.titleIFiler) {
        const { userSourceType } = this.store.getters.get_user
        switch (userSourceType) {
        case 2:
          titleFail.push('customerShortName', 'customerName')
          break //药企
        case 3:
          titleFail.push('customerShortName', 'customerName')
          break //药企
        case 1:
          titleFail.push('channelMerchantShortName', 'channelMerchantName')
          break //服务商
        case 6:
          titleFail.push('channelMerchantShortName', 'channelMerchantName')
          break //服务商
        case 4:
          titleFail.push('agentEnterpriseNumber', 'agentName')
          break //团队
        default:
          break
        }
      }
      for (let item = 0; item < titleList.length; item++) {
        if (titleList[item].filer && titleList[item].render === undefined && titleFail.indexOf(titleList[item].prop) === -1) {
          titleList[item].render = (h, params) => {
            return h('div', {
              attrs: { class: titleList[item].class ? `${titleList[item].class(params.row[titleList[item].prop])} ` : '' },
            }, titleList[item].filer(params.row[titleList[item].prop], params.row, params.index))
          }
        }
        if (titleFail.indexOf(titleList[item].prop) === -1) {
          titlePass.push(titleList[item])
        }
      }

      return titlePass

    },
    isActiveRow(row) {
      let className = ''
      this.table.selection.forEach((item) => {
        if (row.row.id == item.id) {
          className = 'current-row'
        }
      })
      return className
    },
    getParent(name) {
      return this.parent ? this.$parent.$parent[name] : this.$parent[name]
    },
    setParent(name, value, value2) {
      return this.parent ? this.$parent.$parent[name](value, value2) : this.$parent[name](value, value2)
    },
    refresh() {
      this.setParent('search', true)
    },
    filerValue() {
      const exportName = this.exportName
      if (this.rule.isValue(exportName)) {
        this.exportName = ''
      } else {
        this.exportName = exportName.toString().replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')
      }
    },
    modalChange(done) {
      document.querySelector('body').style = ''
      done()
    },
    setListValueEdit(searchValue, setValue) {
      const index = this.table.list.map(x => x[searchValue.prop]).indexOf(searchValue.value)
      if (index !== -1) this.table.list[index][setValue.prop] = setValue.value
    },
    getButtonType(item, type, i) {
      let text = 'default'
      if (type === 'right' && i == 0) {
        text = 'primary'
      }
      return text
    },
    getButtonGhost(item) { //幽灵属性，使按钮背景透明
      let ghost = false
      const buttonGhost = this.value.buttonGhost


      if (buttonGhost) {
        if (buttonGhost[item.functionName]) {
          ghost = buttonGhost[item.functionName]
        }
      }
      return ghost
    },
    getClass(item, type, i) {
      let buttonClass = 'buttondefault'
      if (type === 'right' && i != 0) {
        buttonClass = 'themeColor'
      }
      return buttonClass
    },
    getMenuIf(prop, row, maxLength, maxShow) {
      const obj = this.getMenu[prop]
      if (obj) {
        const list = obj.filter(item => {
          return this.buttonFunState(item, row)
        })
        if (maxLength && maxShow) {
          if (list.length === maxLength + 1) {
            return list
          }
          return list.length < maxLength + 1 ? list : list.filter((x, index) => {
            return index < maxLength
          })
        } else if (maxLength) {
          return list.length < maxLength + 1 ? list : []
        }
        return list
      }
      return []
    },
    showWarning(text) {
      this.$Http.msg.warning({ title: '警告', desc: text })
    },
    getTableValueExport(val) {
      const name = this.exportName
      if (this.rule.isValue(name)) {
        this.showWarning('请填写导出数据的文件标题！')
        return
      } else if (val == '0 - 0') {
        this.showWarning('可导出的数据为零！')
        return
      }
      const data = this.getSearchTableValue()
      data.pageNo = this.exports.pageNo
      data.pageSize = this.exports.pageSize
      this.exports.loading = true
      let ajax = this.value.http ? this.value.http : 'get'
      this.$Http[ajax](this.value.url, data).then(e => {
        if (e.code === 0) {
          this.table.total = e.data.total
          this.exportExcel(e.data.records)
        }
        this.exports.loading = false
      }).catch(() => {
        this.exports.list = []
        this.exports.loading = false
      })
    },
    asyncAdvancedExport() {
      const name = this.exportName
      if (this.rule.isValue(name)) {
        this.showWarning('请填写导出数据的文件标题！')
        return
      }
      if (this.table.total === 0) {
        this.showWarning('可导出的数据为零！')
        return
      }
      const data = this.getSearchTableValue()
      this.exports.loading = true
      let url = this.asyncAdvancedExportUrl
      let params = { ...data, ...this.asyncAdvancedExportParams }
      url = url + '?' + Object.keys(params)
        .filter(key => params[key] !== undefined)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      this.$Http.post(url).then((res) => {
        if (res.code === 0) {
          this.$Http.msg.success({ title: '导出成功', desc: '请至【数据中心-导出历史】中查看下载数据！' })
        }
        this.exports.modal = false
        this.exports.loading = false
      }).catch(() => {
        this.exports.modal = false
        this.exports.loading = false
      })
    },
    changeAffix(val) {
      this.state = val
    },
    // 分页 筛选 排序
    setSelection(val) {
      val.forEach(child => {
        this.$refs.minTableIndex.setCurrentRow(child)
      })
      this.table.selection = val
      this.table.selectionTotal = val.length
      this.$emit('selection', this.table.selection)
    },
    setCustom(val) {
      this.table.searchData.orderAsc = null
      this.table.searchData.orderField = null
      this.getTableValueSubmit()
    },
    // 按钮权限判断
    buttonFunState(item, row, data) {
      if (this.value.buttonShow) {
        if (this.value.buttonShow[item.functionName]) {
          return this.value.buttonShow[item.functionName](row, item, data)
        }
      }
      if (item.funcitonIf) {
        return row[item.funcitonIf] == item.funcitonIfParams ? false : true

      } else {
        return true
      }
    },
    topFun(item) {
      if (item.functionName === 'del') {
        const value = this.delValueFiler(this.table.selection)
        if (value === '') {
          this.showWarning('请选择选项！')
        } else {
          this.del(value, this.table.selection)
        }
      } else if (item.functionName === 'exportExcel') {
        this.exports.pageNo = 1
        this.exports.state = false
        this.exportName = this.defaultExportName ? this.defaultExportName : '导出数据'
        if (this.page) {
          this.exports.modal = true
        } else {
          this.exports.modalMin = true
        }
      } else if (item.functionName === this.exportFileName) {
        this.exports.state = false
        this.exportName = '导出数据'
        this.exports.modal = true
      } else {
        this.setParent(item.functionName, this.table.selection, item)
      }
    },
    tableFun(item, val) {
      this.buttonModal = false
      if (item.functionName === 'del') {
        const value = this.delValueFiler([val])
        this.del(value, val)
      } else if (item.functionName === 'remove') {
        this.remove(val)
      } else {
        this.setParent(item.functionName, Object.assign({}, val), item)
      }
    },
    exportFile() {
      if (this.exportFileName !== 'exportExcel' && this.table.list.length === 0) {
        this.showWarning('可导出的数据为零！')
      } else if (this.exportMax && this.table.list.length > (Number(this.exportMax) * 10000)) {
        this.showWarning(`导出数据已超过${this.exportMax}万条，请重新选择数据！`)
      } else {
        const name = this.exportName
        if (this.rule.isValue(name)) {
          this.showWarning('请填写导出数据的文件标题！')
        } else {
          // console.log(this.exportFileName)
          this.setParent(this.exportFileName, this.getSearchTableValue(), name)
          this.exports.modal = false
        }
      }
    },
    del(ids, val) {
      let name = this.value.delName ? this.value.delName(val) : '您确定要删除该选项吗'
      this.$confirm(name, '提示', {
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            let ajax = this.value.delHttp ? this.value.delHttp : 'postForm'
            const data = {}
            data[this.value.delProp ? this.value.delProp : 'id'] = ids
            this.$Http[ajax](this.value.delUrl, data, '删除').then(() => {
              this.$emit('success')
              this.getTableValue(true)

            }).finally(() => {
              instance.confirmButtonLoading = false
              instance.confirmButtonText = '确定'
              done()
            })
          } else {
            done()
          }
        },
      }).catch(() => '')
    },
    remove(value) {
      this.$confirm(`您确定要${this.value.remove.name}该选项吗?`, '提示', {
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            let data = this.value.remove.data(value)
            this.$Http.postForm(this.value.remove.url, data, name).then(() => {
              this.$emit('success')
              this.getTableValue(true)

            }).finally(() => {
              instance.confirmButtonLoading = false
              instance.confirmButtonText = '确定'
              done()
            })
          } else {
            done()
          }
        },
      }).catch(() => '')
    },
    changePage() {
      this.getTableValueSubmit()
    },
    changePageSize(val) {
      this.$refs.minTableIndex.clearSelection()
      this.table.searchData.pageSize = val
      const maxPage = Math.ceil(this.table.total / val)
      if (maxPage >= this.table.searchData.pageNo) {
        this.getTableValueSubmit()
      }
    },
    getTableValue(state) {
      if (this.table.loading) return
      if (state !== false) this.table.searchData = { pageNo: 1, pageSize: this.table.searchData.pageSize }
      this.getTableValueSubmit(state)
    },
    getSearchTableValue() {
      if (this.search === false) return {
        pageNo: this.table.searchData.pageNo,
        pageSize: this.table.searchData.pageSize,
        orderAsc: this.table.searchData.orderAsc,
        orderField: this.table.searchData.orderField,
      }
      let searcObj = this.getParent('searchList')
      let data = Object.assign({
        pageNo: this.table.searchData.pageNo,
        pageSize: this.table.searchData.pageSize,
        orderAsc: this.table.searchData.orderAsc,
        orderField: this.table.searchData.orderField,
      }, searcObj.data)
      let list = searcObj.title
      for (let item = list.length; item--;) {
        if (list[item].type === 'ymd_2') {
          let time2 = searcObj.data[list[item].prop] || []
          if (time2[0] != '' && time2.length != 0) {
            data[list[item].prop] = `${new Date(time2[0]).pattern('yyyy-MM-dd')} 00:00:00`
            data[list[item].endProp] = `${new Date(time2[1]).pattern('yyyy-MM-dd')} 23:59:59`
          } else {
            delete data[list[item].prop]
            delete data[list[item].endProp]
          }
        } else if (list[item].type === 'datetimerange') {
          let time2 = searcObj.data[list[item].prop] || []
          if (time2[0] != '' && time2.length != 0) {
            data[list[item].prop] = `${new Date(time2[0]).pattern('yyyy-MM-dd HH:mm:ss')}`
            data[list[item].endProp] = `${new Date(time2[1]).pattern('yyyy-MM-dd HH:mm:ss')}`
          } else {
            delete data[list[item].prop]
            delete data[list[item].endProp]
          }
        } else if (list[item].type === 'ymd') {
          if (data[list[item].prop]) {
            data[list[item].prop] = new Date(data[list[item].prop]).pattern('yyyy-MM-dd')
          }
        } else if (list[item].type === 'ym') {
          if (data[list[item].prop]) {
            data[list[item].prop] = new Date(data[list[item].prop]).pattern('yyyy-MM')
          }
        } else if (list[item].type === 'y') {
          if (data[list[item].prop]) {
            data[list[item].prop] = new Date(data[list[item].prop]).pattern('yyyy')
          }
        } else if (list[item].type === 'select' && list[item].multiple) { //多选下拉
          if (data[list[item].prop] && data[list[item].prop].length) {
            data[list[item].prop] = data[list[item].prop].join()
          }
        }else if(list[item].type === 'cascaderArea'){//区域多选
          if (data[list[item].prop] && data[list[item].prop].length) {
            data[list[item].prop] = JSON.stringify(data[list[item].prop])
          }

        }
      }
      for (let item in this.searchValue) {
        data[item] = this.searchValue[item]
      }
      for (let item in data) {
        if (data[item] instanceof Array) {
          delete data[item]
        } else if (data[item] == 'all_hide') {
          delete data[item]
        } else if (data[item]) {
          data[item] = data[item].toString().replace(/(^\s*)|(\s*$)/g, '')
        }
      }
      data = { ...data, ...this.searchValueNew }
      return data
    },
    getTableValueSubmit(state) {
      const value = this.value
      if (value.http === false) return
      if (this.rule.isValue(value.url)) {
        return
      }
      // eslint-disable-next-line no-undefined
      if ((!this.reserveSelection || state !== undefined) && this.$refs.minTableIndex) {
        this.$refs.minTableIndex.clearSelection()
      }
      let data = this.getSearchTableValue()
      this.table.loading = true
      this.$emit('loading', true)
      let ajax = value.http ? value.http : 'get'
      this.$Http[ajax](value.url, data).then(e => {
        if (value.dataFiler) {
          const dataFiler = value.dataFiler(e.data)
          this.table.list = dataFiler.data
          this.table.total = dataFiler.total
          if (this.actualTotal) {
            this.table.actualTotal = dataFiler.actualTotal
          }
        } else if (this.page) {
          this.table.total = e.data.total
          this.table.list = e.data.records
          if (this.actualTotal) {
            this.table.actualTotal = e.data.actualTotal
          }
        } else {
          this.table.list = e.data
        }
        this.$emit('successValue', e.data)
        this.table.loading = false
      }).catch(() => {
        this.table.list = []
        this.table.loading = false
      }).finally(() => {
        this.$emit('loading', false)
      })
    },
    setTableData(data) {
      this.table.searchData = { pageNo: 1, pageSize: this.table.searchData.pageSize }
      this.$refs.minTableIndex.clearSelection()
      this.table.list = data.records || []
      this.table.total = data.total || 0
      if (this.actualTotal) {
        this.table.actualTotal = data.actualTotal || 0
      }
    },
    clearSelection() {
      this.$refs.minTableIndex.clearSelection()
    },
    exportExcel(listData, flag) {
      let list = listData
      if (this.downLoadByParent && this.value.http === false && flag) {
        list = this.value.data

      }
      let value = []
      const name = this.exportName
      if (this.rule.isValue(name)) {
        this.showWarning('请填写导出数据的文件标题！')
      } else {
        let title = this.getTitleExport
        if (this.showExportId) { //id
          title = [{ prop: 'id', label: '终端ID' }].concat(title)
        }
        if (this.showIndex && this.showExportIndex) { //序号
          title = [{ prop: 'exportNumber', label: '序号' }].concat(title)
        }
        for (let i = 0; i < list.length; i++) {
          let obj = JSON.parse(JSON.stringify(list[i]))
          for (let item = title.length; item--;) {
            if (title[item].filer) {
              obj[title[item].prop] = title[item].filer(list[i][title[item].prop], list[i], i)
            } else if (title[item].render) {
              let itemValue = ''
              title[item].render((item1, item2, item3) => {
                itemValue = item3
              }, { row: list[i] })
              obj[title[item].prop] = itemValue
            }
            if (title[item].price) {
              obj[title[item].prop] = obj[title[item].prop].replace(',', '')
            }
            if (typeof obj[title[item].key] === 'number') {
              obj[title[item].prop] = obj[title[item].prop].toString()
            }
          }
          obj.exportNumber = i + 1
          value.push(obj)
        }
        if (value.length == 0) {
          this.showWarning('可导出的数据为零！')
        } else if (this.exportMax && value.length > (Number(this.exportMax) * 10000)) {
          this.showWarning(`导出数据已超过${this.exportMax}万条，请重新选择数据！`)
        } else {
          this.exports.modalMin = false
          this.$Http.msg.success({ title: '提示', desc: `成功导出${value.length} 条数据 !` })
        }
        if (this.summary) {
          const lists = this.value.summary
          const obj = {}
          lists.forEach((column) => {
            const prop = column.prop
            let text = ''
            const values = value.map(item => Number(item[prop]))
            const sum = values.reduce((prev, curr) => {
              return isNaN(Number(curr)) ? prev : NP.plus(prev, curr)
            }, 0)
            // 导出不做千分位处理
            text = column.price ? sum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : sum
            obj[prop] = text
            if (typeof obj[prop] === 'number') {
              obj[prop] = obj[prop].toString()
            }
          })
          obj[title[0].prop] = '合计'
          value.push(obj)
        }
        if (value.length) {
          if ((this.exportMax && value.length <= (Number(this.exportMax) * 10000)) || !this.exportMax) {
            const params = {
              title: title.map(item => item.label),
              key: title.map(item => item.prop),
              data: value,
              autoWidth: true,
              filename: name,
            }
            excel.export_array_to_excel(params)
          }
        }
      }
    },
    handleSummary({ columns, data }) {
      const list = this.value.summary
      const summaryList = []
      const listValue = list.map(item => item.key)
      columns.forEach((column) => {
        const key = column.property
        let text = ''
        const values = data.map(item => Number(item[key]))
        const index = listValue.indexOf(key)
        if (index != -1) {
          const sum = values.reduce((prev, curr) => {
            return isNaN(Number(curr)) ? prev : NP.plus(prev, curr)
          }, 0)
          text = list[index].price ? sum.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : sum
        }
        summaryList.push(text)
      })
      summaryList[0] = '合计'
      return summaryList
    },
    confirm(columns) {
      this.tableColumns=[...columns]
      this.isUpdate = !this.isUpdate;
      this.$nextTick(()=>{
        this.$refs.minTableIndex&&this.$refs.minTableIndex.doLayout()
      })
    },
    doLayout() {
      this.$nextTick(()=>{
        this.$refs.minTableIndex&&this.$refs.minTableIndex.doLayout()
      })
    },
    clearSort(){
      this.$refs.minTableIndex&&this.$refs.minTableIndex.clearSort()
    },
    getSortInfo() {
     return this.$refs.minTableIndex.store.states.sortingColumn
    },
    dragend(newWidth, oldWidth, column, event) {
    column.width = newWidth < 50 ?50 : newWidth
      this.$nextTick(()=>{
        this.$refs.minTableIndex&&this.$refs.minTableIndex.doLayout()
      })
    },
    handleInvertSelection() {
      let selectList = JSON.parse(JSON.stringify(this.table.selection)) //这里储存的是之前选中的数据 通过下一行代码 会将this.selectList清空 所以得先存起来
      this.$refs.minTableIndex.clearSelection() //这里直接将之前选中的清空，直接拿selectList来判断那些需要选择，那些不选
      this.getData.forEach(row => this.$refs.minTableIndex.toggleRowSelection(row,!selectList.some(x => x.id == row.id))) // 反选当前页
      // const otherData = this.tableAll.filter(item => !this.getData.some(x=>x.id == item.id)) //找出剩余需要反选的数据
      // otherData.map(row=>this.$refs.minTableIndex.toggleRowSelection(row,!selectList.some(x => x.id == row.id))) //将剩余数据进行反选
    },
  },
}
</script>
<style lang="scss" scoped>
.table-top-button-wrap {
  display: flex;
  overflow: hidden;
  margin: 20px 0px;

  .flex1 {
    flex: 1;
  }
}

.table-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ::v-deep .el-pagination {
    line-height: 1;
  }
}

.buttondefault {
  margin: 0 0 0 10px;

}

.buttonDel {
  border-color: red;
  color: red;
  margin-left: 8px !important;

}

.refresh {
  margin-left: 8px !important;
}

.buttonDel:hover, .buttonDel:focus {
  color: red;
  background: #fef0f0;
  border-color: #fbc4c4

}

.themeColor {
  color: var(--color-primary, #2ebf73);
  border-color: var(--color-primary, #2ebf73);
}

.padding6 {
  padding: 5px !important;
  font-weight: 600;
  vertical-align: sub;

  ::v-deep i {
    font-weight: 100;
    margin-right: 0;
    font-size: 20px;
  }
}
.min-table{

::v-deep .el-table__header{
  width: 100% !important;
  }
}
</style>
