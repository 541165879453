import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)


const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location)
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: store.getters.get_router_parms,
})
