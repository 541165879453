let transferIndex = 0;
let lastVisibleIndex = 0;

function transferIncrease() {
    transferIndex++;
}

function lastVisibleIncrease() {
    lastVisibleIndex++;
}

export { transferIndex, transferIncrease, lastVisibleIndex, lastVisibleIncrease };