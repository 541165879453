import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "./axios";

Vue.use(Vuex);

const menusDelayering = (value2) => {
  const getAllItem = (value) => {
    const router = [];
    value.forEach((item) => {
      router.push({
        ...item,
        subMenuList: []
      });
      if (item.redirect === undefined && item.subMenuList && item.subMenuList.length) {
        router.push(...getAllItem(item.subMenuList));
      }
    });
    return router;
  }
  return getAllItem(value2 || []);
};
//将字符串的首字母大写，将-和/之后的字母大写，且去除-和/
const capitalizeString=(string)=> {
  return string.replace(/(^|_|-|\/)([a-zA-Z])/g, (match, p1, p2)=> {
    return p2.toUpperCase();
  }).replace(/_|-|\//g, '');
}
const menus = value => {
  const router = []
  if (value === undefined || value === null || value === '') {
    return router
  }
  value.forEach(item => {
    if (item.redirect !== undefined) {
      router.push({path: `/${item.menuUrl}`, redirect: item.redirect, name: item.menuUrl})
    } else {
      const operation = {
        search: false,
        top: [],
        right: [],
        table: [],
        list: [],
        tabs: []
      }
      const childNodeChange = (id, subFunctionsList) => {
        operation[id] = {search: false, top: [], right: [], table: [], list: [], tab: []}
        subFunctionsList.forEach(x => {
          const subFunctionsListNode = x.subFunctionsList || []
          if (subFunctionsListNode.length > 0 || x.funcitonState === 'tab') {
            childNodeChange(x.id, subFunctionsListNode)
          }
          switch (x.funcitonState + '') {
            case '0':
              operation[id].search = true
              break
            case '1':
              operation[id].top.push(x)
              break
            case '2':
              operation[id].right.push(x)
              break
            case '3':
              operation[id].table.push(x)
              break
            case 'tab':
              operation[id].tabs.push({id: x.id, name: x.name})
              break
            default:
              operation[id].list.push(x)
              break
          }
        })
      }
      let list = item.subFunctionsList || []
      list.forEach(x => {
        const subFunctionsList = x.subFunctionsList || []
        if (subFunctionsList.length > 0 || x.funcitonState === 'tab') {
          childNodeChange(x.id, subFunctionsList)
        }
        switch (x.funcitonState + '') {
          case '0':
            operation.search = true
            break
          case '1':
            operation.top.push(x)
            break
          case '2':
            operation.right.push(x)
            break
          case '3':
            operation.table.push(x)
            break
          case 'tab':
            operation.tabs.push({id: x.id, name: x.name})
            break
          default:
            operation.list.push(x)
            break
        }
      })

      const path = item.menuUrl !== "*" ? `/${item.menuUrl}` : item.menuUrl
      router.push({
        path: path,
        meta: {title: item.name, authority: operation, authorityTime: state.authorityTime,keepAlive:true},
        name: capitalizeString(path),
        // 自动拆包 全量
        component: () => import(`../views/${item.menuHref}.vue`),
        // 不拆包

        //component: resolve => require([`../views/${item.menuHref}.vue`], resolve),
        children: menus(item.subMenuList)
      });
    }
  })
  return router
}
const state = {
  keepAliveInclude: [],
  width: 99,
  height: 99,
  authorityTime: '',
  user: {},
  menuList: [],
  recordList: [],
  menuSelect: {
    name: ''
  },
  router_parms: [{menuUrl: "login", menuTitle: "login", menuHref: "login/login"},]
};
for (var item in state) {
  if(axios.cok.get(item)){
    state[item] = axios.cok.get(item)
  }
}
const mutations = {
  set_recordList: (state, n) => {
    state.recordList = n
    axios.cok.set("recordList", state.recordList)
  },
  set_width: (state, n) => {
    state.width = {
      max: n,
      main: n - 220,
      search: n - 220 - 260
    }
    axios.cok.set("width", state.width)
  },
  set_height: (state, n) => {
    state.height = {
      max: n, // 净高度
      main: n - 60, // 基础减去 内部部分外的高度
    };
    axios.cok.set("height", state.height);
  },
  set_menuSelect: (state, n) => {
    state.menuSelect = n;
    if (state.keepAliveInclude.map(x => x.name).indexOf(n.name) === -1 && n.meta.title) {
      state.keepAliveInclude.push({ title: n.meta.title, name: n.name, path: n.path,fullPath: n.fullPath })
    }

    if(state.keepAliveInclude.find(item=>item.name === n.name && item.fullPath !== n.fullPath)) {
      mutations.removeKeepAliveInclude(state,n.name)
      setTimeout(() => {
        state.keepAliveInclude.push({ title: n.meta.title, name: n.name, path: n.path,fullPath: n.fullPath })
      }, 0);
    }

    axios.cok.set("keepAliveInclude", state.keepAliveInclude)
    axios.cok.set("menuSelect", n);
  },
  set_login: (state, n) => {
    state.user = {}
    state.menuList = []
    state.recordList = []
    state.menuSelect = []
    state.authorityTime = `${n.user.id}-&&-${new Date().pattern('yyyy-MM-dd HH:mm:ss')}`
    state.router_parms = [{menuUrl: "login", menuTitle: "login", menuHref: "login/login"}]
    router.addRoutes(menus([
      {menuUrl: "main", menuTitle: "main", menuHref: "home/index", name:'控制台', subMenuList: menusDelayering(n.menuList),
    },
      {menuUrl: "*", menuTitle: "404", menuHref: "warning/404"},
    ]))
    state.router_parms = [
      {menuUrl: "login", menuTitle: "login", menuHref: "login/login"},
      {menuUrl: "main", menuTitle: "main", menuHref: "home/index", name:'控制台', subMenuList:menusDelayering(n.menuList),
    },
      {menuUrl: "*", menuTitle: "404", menuHref: "warning/404"}
    ]
    state.menuList = n.menuList
    state.user = n.user
    axios.cok.setList(
      [
        {name: 'user', value: state.user},
        {name: 'menuList', value: state.menuList},
        {name: 'router_parms', value: state.router_parms},
        {name: 'authorityTime', value: state.authorityTime},
      ]
    )
    router.replace('/main');
  },
  show_back_login: (state) => {
    if (state.menuList.length > 0) {
      mutations.back_login(state)
    }
  },
  back_login: (state) => {
    state.router_parms = [{menuUrl: "login", menuTitle: "login", menuHref: "login/login"}]
    state.menuList = []
    state.user = {}
    axios.cok.delAll();
    router.replace('/login');
    setTimeout(() => {
      window.location.reload();
    }, 300);
  },
  menuListIf: (state, {url, name, query, isFun}) => {
    const forDG = (arr, menuUrl, last) => {
      if (last === undefined) {
        last = []
      }
      for (let i = arr.length; i--;) {
        if (arr[i].menuUrl === menuUrl) {
          last.push(arr[i])
          return {state: true, data: last, name: arr[i].title, menuUrl: arr[i].menuUrl}
        } else {
          let arrFirst = []
          arrFirst = Object.assign(arrFirst, last)
          arrFirst.push(arr[i])
          if (arr[i].subMenuList !== null && arr[i].subMenuList !== undefined && arr[i].subMenuList.length !== 0) {
            let state = forDG(arr[i].subMenuList, menuUrl, arrFirst)
            if (state.state) {
              return state
            }
          }
        }
      }
      return {state: false, data: []}
    }
    const obj = forDG(state.menuList, url)
    const success = () => {
      router.push({
        path: `/${url}`,
        query: query
      })
    }
    const error = (text) => {
      axios.msg.warning({title: '警告', desc: `您没有${text}的权限，请联系管理员开启！`})
    }
    if (obj.state) {
      if (isFun !== undefined) {
        isFun(obj.data, success, error)
      } else {
        success()
      }
    } else {
      error(name)
    }
  },
  nextSurvey(state, n) {
    axios.get('survey/getSurveyPageLink', {id: n.id, surveyLinkType: n.surveyLinkType}).then(e => {
      window.open(e.data)
    }).catch(() => {
    })
  },
  removeKeepAliveInclude(state, val) {
    // state.keepAliveInclude.splice(state.keepAliveInclude.map(x => x.name).indexOf(val), 1)
    const index = state.keepAliveInclude.map(x => x.name).indexOf(val)
    if (index !== -1) {
      state.keepAliveInclude.splice(state.keepAliveInclude.map(x => x.name).indexOf(val), 1)
      axios.cok.set("keepAliveInclude", state.keepAliveInclude);
    }
  },
  removeKeepAliveIncludeAll(states, val) {
    states.keepAliveInclude = val !== -1 ? [states.keepAliveInclude[val]] : []
    axios.cok.set("keepAliveInclude", states.keepAliveInclude);
  }
};
const getters = {
  get_width: state => {
    return state.width;
  },
  get_height: state => {
    return state.height;
  },
  get_user: state => {
    return state.user;
  },
  get_menuList: state => {
    return state.menuList;
  },
  get_menuSelect: state => {
    return state.menuSelect;
  },
  get_authorityTime: state => {
    return state.authorityTime;
  },
  get_recordList: state => {
    return state.recordList
  },
  get_router_parms: state => {
    const routers = JSON.parse(JSON.stringify(state.router_parms))
    return menus(routers);
  },
  getKeepAliveInclude(state) {
    return state.keepAliveInclude;
  },
};
const actions = {
}
export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
});
