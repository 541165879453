import Vue from 'vue'
import App from './App.vue'
import router from './config/router'
import store from './config/store'
import axios from './config/axios'
import rule from './config/rule'
import enumerationLibrary from './config/enumerationLibrary'
import minTable from './components/table/index'
import search from './components/search/search'
import tab from './components/tab/index'
import seeItem from './components/see/seeItem'
import ZBack from './components/detaileItem/back'
import ZFooter from './components/detaileItem/footer'
import ZAlert from './components/alert/index'
import { projectInfo } from '@/config/project'
import { optionalChaining } from './utils/util.js'

// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

import Spin from './components/spin/spin'
/** 导入全局css */
import '@/style/main.scss'
// 导入 ckeditor.css
import '@/style/ckeditor.css'
const { version } = require('../package.json')

/** 注册element*/
import { setupElement } from '@/core/elemetUI'
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tag,
  Tree,
  Icon,
  Row,
  Col,
  Upload,
  Empty,
  Progress,
  Spinner,
  Card,
  Cascader,
  Timeline,
  TimelineItem,
  Divider,
  Image,
  CascaderPanel,
  Loading,
  MessageBox,
  Notification,
  Drawer,
} from 'element-ui'
setupElement()
import '@/style/element_visiable.scss'
Vue.use(Drawer)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Tooltip)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Spinner)
Vue.use(Card)
Vue.use(Cascader)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Empty)
Vue.use(CascaderPanel)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$notify = Notification
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }


Vue.component('MinTable', minTable)
Vue.component('ZSearch', search)
Vue.component('SeeItem', seeItem)
Vue.component('ZAlert', ZAlert)
Vue.component('ZBack', ZBack)
Vue.component('ZFooter', ZFooter)
Vue.component('Tab', tab)
Vue.component('Spin', Spin)


Vue.config.productionTip = false
Vue.prototype.$$ = optionalChaining
Vue.prototype.rule = rule
Vue.prototype.store = store
Vue.prototype.$Http = axios
Vue.prototype.$projectInfo = projectInfo
Vue.prototype.enumerationLibrary = enumerationLibrary
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'mzL5buk0WT0Eo3MjtEadjT9CTtZ18EhT',
})

import './premission'

// cnpm i @babel/preset-env -D
// cnpm install @vue/babel-preset-jsx @vue/babel-helper-vue-jsx-merge-props --save
/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * eg:
 * (new Date()).pattern("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).pattern("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).pattern("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).pattern("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).pattern("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 */
Date.prototype.pattern = function dataChange(fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours() % 24 == 0 ? (this.getHours() == 0 ? 0 : 24) : this.getHours() % 24, //小时
    'H+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds(), //毫秒
  }
  var week = {
    '0': '/u65e5',
    '1': '/u4e00',
    '2': '/u4e8c',
    '3': '/u4e09',
    '4': '/u56db',
    '5': '/u4e94',
    '6': '/u516d',
  }
  let value = fmt
  if (/(y+)/.test(value)) {
    value = value.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(value)) {
    value = value.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[this.getDay() + ''])
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(value)) {
      value = value.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return value
}
// if (process.env.NODE_ENV !== 'development') {
// Sentry.init({
//   Vue,
//   dsn: 'https://ea6f3f33d7174d818b27eea54b5f7792@sentry.successhr.com/3',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', /^\//],
//     }),
//   ],
//   denyUrls: [/http:\/\/localhost:8080/i],
//   ignoreErrors: ['ResizeObserver loop limit exceeded'],
//   tracesSampleRate: 1.0,
//   release: version,
//   environment: process.env.VUE_APP_API_ENV,
// })
// }

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

const setWidth = () => {
  const b = document.documentElement.clientWidth
  const d = document.documentElement.clientHeight
  store.commit('set_width', b)
  store.commit('set_height', d)
  window.addEventListener('resize', () => {
    const w = document.documentElement.clientWidth
    const h = document.documentElement.clientHeight
    store.commit('set_width', w)
    store.commit('set_height', h)
  })
}
setWidth()
