// 七里香

module.exports = {
  development: {
    ENV_NAME: '开发环境',
    API_BASE_URL: '/uker-medicine-site',
    PROXY_TARGET: 'http://124.223.42.4:26011',
    // PROXY_TARGET: 'http://47.99.58.219',
    PUBLIC_PATH: '/uker-medicine',
    CALLCENTER: 'https://callcenter-qa.successhr.com',

  },
  release: {
    ENV_NAME: '测试环境',
    API_BASE_URL: '/uker-medicine-site',
    PROXY_TARGET: 'https://medicine-qa.ukerw.com',
    PUBLIC_PATH: '/uker-medicine',
    CALLCENTER: 'https://callcenter-qa.successhr.com',
  },
  demo: {
    ENV_NAME: '演示环境',
    API_BASE_URL: '/uker-medicine-site',
    PROXY_TARGET: 'https://medicine-demo.ukerw.com',
    PUBLIC_PATH: '/',
    CALLCENTER: 'https://callcenter.successhr.com',
  },
  production: {
    ENV_NAME: '生产环境',
    API_BASE_URL: '/uker-medicine-site',
    PROXY_TARGET: 'https://medicine.ukerw.com',
    PUBLIC_PATH: '/',
    CALLCENTER: 'https://callcenter.successhr.com',
  },
}
