/**
 * 红树森林
 * development、release环境目前暂时不能用
 */

module.exports = {
  development: {
    ENV_NAME: '开发环境',
    API_BASE_URL: '/uker-hssl-site',
    PROXY_TARGET: 'http://47.111.108.57',
    PUBLIC_PATH: '/uker-hssl',
    CALLCENTER: 'https://callcenter-qa.successhr.com',
  },
  release: {
    ENV_NAME: '测试环境',
    API_BASE_URL: '/uker-hssl-site',
    PROXY_TARGET: 'http://121.41.206.73',
    PUBLIC_PATH: '/uker-hssl',
    CALLCENTER: 'https://callcenter-qa.successhr.com',
  },
  demo: {
    ENV_NAME: '演示环境',
    API_BASE_URL: '/uker-hssl-site',
    PROXY_TARGET: 'https://hssl-demo.ukerw.com',
    PUBLIC_PATH: '/',
    CALLCENTER: 'https://callcenter-qa.successhr.com',
  },
  production: {
    ENV_NAME: '生产环境',
    API_BASE_URL: '/uker-hssl-site',
    PROXY_TARGET: 'https://hssl.ukerw.com',
    PUBLIC_PATH: '/',
    CALLCENTER: 'https://callcenter.successhr.com',
  },
}
