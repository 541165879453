import router from '@/config/router'
import store from '@/config/store'

router.beforeEach((to, from, next) => {
  /* eslint-disable */
    if (window._hmt) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
    if (to.path !== "/login") {
        if (store.getters.get_user.userSourceType != undefined) {
            store.commit('set_menuSelect', {meta: to.meta, name: to.name, path: to.path, fullPath: to.fullPath})
            if (to.path === "/") {
                next("/main")
            } else {
                next()
            }
        } else {
            next("/login")
            store.commit("back_login", {name: 'userSourceType未定义导致退出', obj: store.getters.get_user})
        }
    } else {
        next()
    }
})
router.afterEach(to => {
    window.scrollTo(0, 0)
})
