import axios from '../config/axios'
import TypeOtherRules from '../views/main/common_control/components/TypeOtherRules'
import { MessageBox, Notification } from 'element-ui'
/**
 * 下载文件（一次下载多个文件）
 */
export const downloadFile = (url) => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none' // 防止影响页面
  iframe.style.height = 0 // 防止影响页面
  iframe.src = url
  document.body.appendChild(iframe) // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
  setTimeout(() => {
    iframe.remove()
  }, 5 * 60 * 1000)
}

/**
 * 设置省市区全局数据
 * @param {*} city
 */
export const setCityData = async(city = []) => {
  let cityCopy = []
  const locality  =  JSON.parse(localStorage.getItem('city')) || []
  if (locality.length > 0) {
    cityCopy = locality
  } else {
    const data = await axios.get('address/all')
    if (data.code == 0) {
      cityCopy = data.data || []
    }
  }
  return cityCopy
  // }
}

export const virtualFormItem = {
  props: {
    visibleKeys: Array,
  },
  render() {
    const vNodes = this.$slots.default
    const filteredVNodes = vNodes.filter(node => this.visibleKeys.includes(node.key))
    return <div class='filterDiv'>{ filteredVNodes }</div>
  },

}


//百度坐标转高德（传入经度、纬度）
export const bdDecrypt = function(bdLng, bdLat) {
  var X_PI = Math.PI * 3000.0 / 180.0
  var x = bdLng - 0.0065
  var y = bdLat - 0.006
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI)
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI)
  var ggLng = z * Math.cos(theta)
  var ggLat = z * Math.sin(theta)
  return { lng: ggLng, lat: ggLat }
}
//高德坐标转百度（传入经度、纬度）
export const bdEncrypt = function(ggLng, ggLat) {
  var X_PI = Math.PI * 3000.0 / 180.0
  var x = ggLng, y = ggLat
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
  var bdLng = z * Math.cos(theta) + 0.0065
  var bdLat = z * Math.sin(theta) + 0.006
  return {
    bdLat: bdLat,
    bdLng: bdLng,
  }
}


/**
 * 解决Vue Template模板中无法使用可选链的问题
 * @param obj
 * @param rest
 * @returns {*}
 */
export const optionalChaining = (obj, ...rest) => {
  let tmp = obj
  for (let key in rest) {
    let name = rest[key]
    tmp = tmp?.[name]
  }
  return tmp || ''
}


/**
 * 其他合规设置数据格式处理
 * @param obj
 * @param rest
 * @returns {*}
 */
export const getOtherRuleList = (taskTypeConfig, flag) => {
  let innitOtherForm = {}

  let initTypeOther = {}
  let newTaskTypeConfig = {}
  let taskTypeConfigForm = {}

  TypeOtherRules.forEach(v => {
    const key = v.key
    const hasFlag = taskTypeConfig.hasOwnProperty(key)
    let value =  {}
    if (hasFlag) {
      const val = taskTypeConfig[key] || '{}'
      value = JSON.parse(val) || {}
    }
    initTypeOther[key] = value
    newTaskTypeConfig[key] = value
    taskTypeConfigForm = { ...taskTypeConfigForm, ...value }

    v.content.forEach(x => { //服务事项编辑页面初始赋值
      const obj = {}
      obj[x.key + 'Flag'] = 0
      if (x.key != 'video') {
        obj[x.key + 'Num'] = ''
      }
      innitOtherForm = { ...innitOtherForm, ...obj }

    })

  })

  let list = {}
  if (flag) {
    list = { ...initTypeOther, ...newTaskTypeConfig }
  } else {
    list = {
      ...innitOtherForm,
      ...taskTypeConfigForm,
    }
  }
  return list

}

/**
 * 详情页展示已经开启的合规
 * @param typeOtherRulesData
 * @param list
 * @returns {*}
 */
export const getShowOtherRule = (typeOtherRulesData, list) => {
  let newList = []
  let newKeyList = []
  typeOtherRulesData.forEach(v => {
    const key = v.key
    const currrnetList = list[key]
    v.content.forEach(x => {
      if (currrnetList[x.key + 'Flag'] == 1) {
        if (newKeyList.includes(key)) {
          const item = newList.find(i => i.key == key)
          item.content.push({
            name: x.name,
            key: x.key,
            [x.key + 'Num']: currrnetList[x.key + 'Num'] || 0,
            unit: x.unit,
            iconSign: x.iconSign || 0,

          })


        } else {
          const obj = {
            key: key,
            name: v.name,
            content: [
              {
                name: x.name,
                key: x.key,
                [x.key + 'Num']: currrnetList[x.key + 'Num'] || 0,
                unit: x.unit,
                iconSign: x.iconSign || 0,

              },
            ],
          }
          newKeyList.push(key)
          newList.push(obj)
        }
      }

    })

  })

  return newList

}


/**
 * @description: 深克隆方法
 * @param {any} obj 源数据
 * @return {any} 克隆后的数据
 */

export const deepClone = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  let result
  if (Array.isArray(obj)) {
    result = []
    for (let i = 0; i < obj.length; i++) {
      result.push(deepClone(obj[i]))
    }
  } else {
    result = {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = deepClone(obj[key])
      }
    }
  }
  return result
}

/**
 * @description: 判断文本框是否溢出
 * @param {any} e 事件对象
 * @return {any} true为溢出  false为不溢出
 */
export const isBeyond = (e) => {
	const textContent = e
	const clientW = textContent.clientWidth
	const scrollW = textContent.scrollWidth
	return scrollW > clientW		// true为溢出  false为不溢出
}

export const handleConfirm=(obj)=>{
  const {title,titleTip='提示',url,params,urlType,urlTip,confirmBack,successBack,cancelBack,showCancelButton=true,distinguishCancelAndClose=true,confirmButtonText='确定',cancelButtonText='取消'}=obj
  MessageBox.confirm(title, titleTip, {
    showCancelButton: showCancelButton,
    distinguishCancelAndClose: distinguishCancelAndClose,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    type: 'warning',
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        if(confirmBack){
          confirmBack(instance,done)
        }else{
          instance.confirmButtonLoading = true
          instance.confirmButtonText = '执行中...'
          let postType=urlType||'postForm'
          axios[postType](url, params, urlTip).then(() => {
            successBack&&successBack()
          }).finally(() => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
            done()
          })
        }
        
      } else if (action === 'cancel') {//参考三要素
        if(cancelBack){
          cancelBack(instance,done)
        }else{
          done()
        }
        
      }else{
        done()
      }
    },
  }).catch(() => '')

}



