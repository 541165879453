<template>
  <div class="see-body">
    <label v-if="labelState" class="see-body-title" :style="`width: ${labelWidth}px;${labelStyle}`">{{ label }} {{ labelCtate ? '：' : '' }}</label>
    <div v-if="labelState" class="see-body-content" :style="`margin-left:  ${labelWidth + 2}px;`">
      <p class="see-auto-item" :style="contentStyle">
        <slot />
      </p>
    </div>
    <p v-else class="see-auto-item" :style="contentStyle">
      <slot />
    </p>
  </div>
</template>
<script>

export default {
  name: 'See',
  props: {
    label: {
      type: [Number, String],
      default: '',
    },
    labelState: {
      type: Boolean,
      default: true,
    },
    labelCtate: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: Number,
      default: 100,
    },
    labelStyle: {
      type: String,
      default: '',
    },
    contentStyle: {
      type: String,
      default: '',
    },
  },
}
</script>
