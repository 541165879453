import rule from './rule'
const enumerationLibrary = {
  map: {
    recordFrequencyUnit: new Map([
      ['3', {
        value: '次/天', disabled: x => {
          return x.recordFrequencyRestriction !== '1'
        },
      }],
      ['6', {
        value: '次/周', disabled: x => {
          return x.recordFrequencyRestriction !== '1'
        },
      }],
      ['2', {
        value: '次/月', disabled: x => {
          return x.recordFrequencyRestriction !== '1'
        },
      }],
      ['1', {
        value: '次/年', disabled: x => {
          return x.recordFrequencyRestriction !== '1'
        },
      }],
      ['8', {
        value: '天/次', disabled: x => {
          return x.recordFrequencyRestriction !== '2'
        },
      }],
      ['7', {
        value: '周/次', disabled: x => {
          return x.recordFrequencyRestriction !== '2'
        },
      }],
      ['4', {
        value: '月/次', disabled: x => {
          return x.recordFrequencyRestriction !== '2'
        },
      }],
      ['5', {
        value: '次', disabled: x => {
          return x.recordFrequencyRestriction !== '3'
        },
      }],
      ['9', {
        value: '日/周', disabled: x => {
          return x.recordFrequencyRestriction !== '4'
        },
      }],
      ['10', {
        value: '日/月', disabled: x => {
          return x.recordFrequencyRestriction !== '4'
        },
      }],
      ['11', {
        value: '日/年', disabled: x => {
          return x.recordFrequencyRestriction !== '4'
        },
      }],
      ['12', {
        value: '天/日', disabled: x => {
          return x.recordFrequencyRestriction !== '5'
        },
      }],
      ['13', {
        value: '周/日', disabled: x => {
          return x.recordFrequencyRestriction !== '5'
        },
      }],
      ['14', {
        value: '月/日', disabled: x => {
          return x.recordFrequencyRestriction !== '5'
        },
      }],

    ]),
  },
  getMap(prop) {
    const list = []
    enumerationLibrary.map[prop].forEach((value, key) => {
      list.push({ key: key, value: value.value, disabled: value.disabled })
    }, enumerationLibrary.map[prop])
    return list
  },
  getMapAndAll(prop) {
    const list = [
      { key: 'all_hide', value: '全部' },
    ]
    enumerationLibrary.map[prop].forEach((value, key) => {
      list.push({ key: key, value: value.value, disabled: value.disabled })
    }, enumerationLibrary.map[prop])
    return list
  },
  getMapText(prop, val) {
    const value = enumerationLibrary.map[prop].get(val)
    return value || {
      key: null,
      value: '',
    }
  },
  getValue(prop, val) {
    return enumerationLibrary.getMapText(prop, val).value
  },
  getIsTextValue(prop, val) {
    return rule.isText(val) === '-' ? '-' : enumerationLibrary.getValue(prop, val)
  },
  getStringValue(prop, val) {
    return enumerationLibrary.getValue(prop, val + '')
  },
  getObjectValue(prop, val) {
    return enumerationLibrary.getValue(prop, val[prop])
  },
  getObjectStringValue(prop, val) {
    return enumerationLibrary.getValue(prop, val[prop] + '')
  },
}
export default enumerationLibrary
