import Vue from 'vue'
import {
  Button,
  Header,
  Aside,
  Main,
  Select,
  Option,
  Menu,
  MenuItem,
  Submenu,
  Pagination,
  Input,
  InputNumber,
  Alert,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Divider,
  Row,
  Col,
  Container,
  Loading,
  DatePicker,
  Calendar,
  CascaderPanel,
  Tag,
  Form,
  FormItem,
  Cascader,
  Dialog,
  Popover,
  RadioGroup, Radio, RadioButton,
  Tooltip,
  Tree,
  CheckboxGroup, Checkbox,
  Timeline, TimelineItem,
  Collapse, CollapseItem,
  Table, TableColumn, Empty,
  Image,
  Steps, Step,
  Tabs, TabPane,
} from 'element-ui'

export const setupElement = () => {
  Vue.use(Popover)
  Vue.use(Button)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Menu)
  Vue.use(MenuItem)
  Vue.use(Submenu)
  Vue.use(Pagination)
  Vue.use(Input)
  Vue.use(InputNumber)
  Vue.use(Alert)
  Vue.use(Dropdown)
  Vue.use(DropdownItem)
  Vue.use(DropdownMenu)
  Vue.use(Divider)
  Vue.use(Col)
  Vue.use(Row)
  Vue.use(Container)
  Vue.use(Header)
  Vue.use(Main)
  Vue.use(Aside)
  Vue.use(Loading)
  Vue.use(DatePicker)
  Vue.use(Calendar)
  Vue.use(CascaderPanel)
  Vue.use(Tag)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Cascader)
  Vue.use(Dialog)
  Vue.use(RadioGroup)
  Vue.use(Radio)
  Vue.use(RadioButton)
  Vue.use(Tooltip)
  Vue.use(CheckboxGroup)
  Vue.use(Checkbox)
  Vue.use(Timeline)
  Vue.use(Tree)
  Vue.use(TimelineItem)
  Vue.use(Collapse)
  Vue.use(CollapseItem)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Empty)
  Vue.use(Image)
  Vue.use(Steps)
  Vue.use(Step)
  Vue.use(Tabs)
  Vue.use(TabPane)
}
