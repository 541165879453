/**
 * 除了内置的 process.env.VUE_APP_API_ENV (环境变量) 和 process.env.BASE_URL (应用的基本路径)
 * 其他都应通过引用此js来获取对应企业的对应环境的配置信息
 * 注：通过commonjs（require）的方式引入
 */
const { currentProject } = require("../project.config.js");
const envConfig = require(`./${currentProject}.js`);
const env = envConfig[process.env.VUE_APP_API_ENV || 'development'];

module.exports = env;
