<template>
  <div>
    <div class="tab-body" >
      <el-button type="text" :class="item.id == value ? 'tab-span-check' : 'tab-span'" :disabled="loading" v-for="item in list" :key="item.id" 
            @click="setTab(item.id)">{{item.name}}</el-button>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Number, String],
        default: 1
      },
      list: {
        type: Array,
        default: () => {
          return []
        }
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        // list: [
        //   {id: 1, name: 'Tab-A'},
        //   {id: 2, name: 'Tab-B'},
        //   {id: 3, name: 'Tab-C'},
        //   {id: 4, name: 'Tab-D'},
        // ],
        // value: 1
      }
    },
    methods: {
      setTab(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  }
</script>
<style scoped>
.tab-span-check{
  outline: 0;
  border: 0;
  border-bottom: 2px solid #2d8cf0;
}
.tab-span{
  color: #515a6e;
}
</style>
