<template>
    <div class="title-detailed">
        <div class="title-detailed-button" @click="$emit('close')">
            <i class="el-icon-back"/>
            {{backName}}
        </div>
        <span class="detailed">{{title}}<slot></slot></span>
    </div>
</template>
<script>
    export default {
        name: 'zBack',
        props: {
            title: {
                type: [Number, String],
                default: '请填写标题',
                required: true
            },
            backName: {
                type: [Number, String],
                default: '返回'
            }
        },
    }
</script>
