const rule = {
  zipFile: (file, max, quality = 0.9) => {
    // 1048576 是 1MB 即 1024 * 1024
    return new Promise((resolve, reject) => {
      try {
        if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
          reject('请上传 JPG 或 PNG 格式图片！')
        } else if (file.size > 15 * 1048576) {
          reject('请上传小于15MB的图片！')
        } else if (file.size < 1048576) {
          resolve(file)
        }
        let ready = new FileReader()
        let obj = {
          width: 1000,
          quality: 1,
        }
        ready.readAsDataURL(file)
        ready.onload = function readyload() {
          let path = this.result
          let img = new Image()
          img.src = path
          img.onload = function imgload() {
            const that = this
            // 默认按比例压缩
            let w = that.width,
              h = that.height,
              scale = w / h
            w = obj.width || w
            h = obj.height || (w / scale)
            //生成canvas
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            // 创建属性节点
            const anw = document.createAttribute('width')
            anw.nodeValue = w
            let anh = document.createAttribute('height')
            anh.nodeValue = h
            canvas.setAttributeNode(anw)
            canvas.setAttributeNode(anh)
            ctx.drawImage(that, 0, 0, w, h)
            // quality值越小，所绘制出的图像越模糊
            const urlData = canvas.toDataURL('image/jpeg', quality)
            // 回调函数返回base64的值
            let arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }
            const fileBlob = new Blob([u8arr], { type: mime })
            if (max * 1048576 >= fileBlob.size) {
              resolve(fileBlob)
            } else {
              this.zipFile(file, quality - 0.09).then(successZipFile => {
                resolve(successZipFile)
              }).catch(() => {
                reject('图片压缩异常！')
              })
            }
          }
        }
      } catch (err) {
        reject('图片压缩异常！')
      }
    })
  },
  keyupTrimNumber: (val, min = 0) => {
    let text = val.toString()
    text = text.replace(/^(0+)|[^\d]+/g, '')
    text = text ? text : min
    text = text < min ? min : text
    text = text > 1000000 ? 999999 : text
    if (isNaN(text)) text = min
    if (text !== 0 || val !== 0) {
      text = rule.isValue(val) ? '' : text
    }
    return text
  },
  keyupTrimSelect: (val) => {
    const iconRule2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*\uFE0F\u20E3|[0-9]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
    return val ? val.toString().replace(/(^\s*)|(\s*$)/g, '').replace(iconRule2, '') : ''
  },
  keyupTrimAll: (val) => {
    const iconRule1 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im
    const iconRule2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
    return rule.isValue(val) ? '' : val.toString().replace(/(^\s*)|(\s*$)/g, '').replace(iconRule1, '').replace(iconRule2, '')
  },
  priceFormat: (val) => {
    if (typeof val == 'undefined' || val === null || val == '' || val == '0') {
      return '0.00'
    }
    if (!val) return ' '
    return parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  },
  isValue: (val) => {
    if (val === '' || val === null || typeof val == 'undefined') return true
    return false
  },
  isText: (val) => {
    return rule.isValue(val) ? '-' : val
  },
  isEqual: (num1, num2) => {
    return num1 + '' === num2 + ''
  },
  isValueShow: (val) => {
    if (val === '' || val === null || typeof val == 'undefined') return '-'
    return val
  },
  getFloat: (val) => {
    const number = parseFloat(val)
    return isNaN(number) ? '' : number
  },
  getIndexValue: (list, val, key) => {
    const index = list.map(x => x[key ? key : 'id']).indexOf(val)
    if (index === -1) {
      return false
    } else {
      return list[index]
    }
  },
  getSelectValue: (list, val, key, label) => {
    if (val) {
      const value = rule.getIndexValue(list, val, key)
      return value === false ? '' : value[label]
    } else {
      return ''
    }
  },
  getNewArray: (list) => {
    return JSON.parse(JSON.stringify(list))
  },
  getArrayIsValue: (list, val) => {
    return list.some(x => x === val)
  },
  getObjectArrayIsValue: (list, key, val) => {
    return list.map(x => x[key]).some(x => x === val)
  },
  getObjectArrayValueAll: (list, key, val) => {
    const value = list.filter(x => {
      return x[key] === val
    })
    if (value.length === 0) {
      return false
    }
    return value
  },
  getObjectArrayValue: (list, key, val) => {
    const value = list.filter(x => {
      return x[key] === val
    })
    if (value.length === 0) {
      return false
    }
    return value[0]
  },
  tableFiler: {
    isRuleRestriction: ['关闭', '开启'],
    isMedicineDataRestriction: ['不开启', '开启'],
    //objectRestriction: ['', '工作区域', '终端', '科室', '医生'],
    objectRestriction: ['', '工作区域', '终端', '终端+科室', '人员'],
    employeeRestrictionRange: ['', '终端+人员', '终端+科室+人员', '仅人员'], //（人员）限制范围
    regionRestrictionRange: ['', '省', '市', '区/县'],
    recordFrequencyRestriction: ['', '频次限制', '间隔限制', '次数限制', '日期频次', '日期间隔'],
    recordFrequencyUnit: ['', '次/年', '次/月', '次/天', '月/次', '次', '次/周', '周/次', '天/次', '日/周', '日/月', '日/年', '天/日', '周/日', '月/日'],
  },
  tableFilerFun: {
    isRuleRestriction: (val) => {
      return rule.isValue(val) ? '' : rule.tableFiler.isRuleRestriction[val]
    },
    isMedicineDataRestriction: (val) => {
      return rule.isValue(val) ? '' : rule.tableFiler.isMedicineDataRestriction[val]
    },
    objectRestriction: (val) => {
      return rule.isValue(val) ? '-' : rule.tableFiler.objectRestriction[val]
    },
    employeeRestrictionRange: (val) => {
      return rule.isValue(val) ? '-' : rule.tableFiler.employeeRestrictionRange[val]
    },
    regionRestrictionRange: (val) => {
      return rule.isValue(val) ? '' : rule.tableFiler.regionRestrictionRange[val]
    },
    recordFrequencyRestriction: (val) => {
      return rule.isValue(val) ? '' : rule.tableFiler.recordFrequencyRestriction[val] + '：'
    },
    recordFrequencyUnit: (val) => {
      return rule.isValue(val) ? '' : rule.tableFiler.recordFrequencyUnit[val]
    },
  },
  getSelectPayrollStatus: {
    type: 'select', label: '批次状态', prop: 'payrollStatus', setLabel: 'name', setValue: 'id', list: [
      { id: '0', name: '待付款' },
      { id: '1', name: '处理中' },
      { id: '2', name: '发放成功' },
      { id: '3', name: '系统退回' },
      { id: '4', name: '有退回' },
      { id: '5', name: '发放中' },
      { id: '6', name: '已取消' },
    ],
  },
  getSelectPayrollStatusCustomer: {
    type: 'select', label: '批次状态', prop: 'payrollStatus', setLabel: 'name', setValue: 'id', list: [
      { id: '0', name: '待付款' },
      { id: '1', name: '处理中' },
      { id: '2', name: '发放成功' },
      { id: '3', name: '系统退回' },
      { id: '4', name: '已撤销' },
      { id: '5', name: '发放中' },
      { id: '6', name: '已退回' },
      { id: '7', name: '已取消' },
    ],
  },
  getSelectDocumentType: {
    type: 'select', label: '证件类型', prop: 'documentType', setLabel: 'value', setValue: 'key', list: [
      { key: '0', value: '大陆身份证' },
      { key: '1', value: '港澳通行证' },
      { key: '2', value: '回乡证' },
      { key: '3', value: '护照' },
      { key: '4', value: '其他证件' },
    ],
  },
  getTableDocumentType: {
    prop: 'documentType', label: '证件类型', minWidth: 115, filer: (val) => {
      return ['大陆身份证', '港澳通行证', '回乡证', '护照', '其他证件'][val]
    },
  },
  getTableDocumentTypeCustom: {
    prop: 'documentType', label: '证件类型', minWidth: 115, sortable: 'custom', filer: (val) => {
      return ['大陆身份证', '港澳通行证', '回乡证', '护照', '其他证件'][val]
    },
  },
  getTablePayrollStatusCustomer: {
    prop: 'payrollStatus', label: '发放状态', minWidth: 105, filer: (val) => {
      return rule.getPayrollStatusCustomer(val)
    }, class: (val) => {
      return rule.getColorPayrollStatus(val)
    },
  },
  getTablePayrollStatus: {
    prop: 'payrollStatus', label: '批次状态', minWidth: 105, filer: (val) => {
      return rule.getPayrollStatus(val)
    }, class: (val) => {
      return rule.getColorPayrollStatus(val)
    },
  },
  getTablePayrollStatusAndCheckStatus: {
    prop: 'payrollStatus', label: '批次状态', minWidth: 105, filer: (val, row) => {
      let check = rule.getCheckStatus(row.checkStatus)
      return `${rule.getPayrollStatus(val)} ${check === '未申请' ? '' : ' - ' + check}`
    }, class: (val) => {
      return rule.getColorPayrollStatus(val)
    },
  },
  getPayrollStatusCustomer: (val) => {
    let arr = {
      '0': '待付款',
      '1': '处理中',
      '2': '发放成功',
      '3': '系统退回',
      '4': '已撤销',
      '5': '发放中',
      '6': '已退回',
      '7': '已取消',
    }
    return arr[val]
  },
  getPayrollStatus: (val) => {
    let arr = {
      0: '待付款',
      1: '处理中',
      2: '发放成功',
      3: '系统退回',
      4: '有退回',
      5: '发放中',
      6: '已取消',
    }
    return arr[val]
  },
  getColorPayrollStatus: (val) => {
    let arr = {
      0: 'table-gray',
      1: 'table-green',
      2: 'table-blue',
      3: 'table-red',
      4: 'table-red',
      5: 'table-green',
      6: 'table-gray',
      7: 'table-gray',
    }
    return arr[val]
  },
  getSelectCheckStatus: {
    type: 'select', label: '审核状态', prop: 'checkStatus', setLabel: 'name', setValue: 'id', list: [
      { id: '0', name: '未申请' },
      { id: '1', name: '待审核' },
      { id: '2', name: '审核通过' },
      { id: '3', name: '审核不通过' },
    ],
  },
  getTableCheckStatus: {
    prop: 'checkStatus', label: '审核状态', minWidth: 120, filer: (val) => {
      return rule.getCheckStatus(val)
    }, class: (val) => {
      return rule.getColorCheckStatus(val)
    },
  },
  getCheckStatus: (val) => {
    let arr = {
      0: '未申请',
      1: '待审核',
      2: '审核通过',
      3: '审核不通过',
    }
    return arr[val]
  },
  getColorCheckStatus: (val) => {
    let arr = {
      0: 'table-gray',
      1: 'table-yellow',
      2: 'table-blue',
      3: 'table-red',
    }
    return arr[val]
  },
  getBatchNo: { prop: 'batchNo', label: '批次号', minWidth: 180 },
  getInputBatchNo: { type: 'input', label: '批次号', prop: 'batchNo' },
}
export default rule
