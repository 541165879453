import Axios from 'axios'
import { MessageBox, Notification } from 'element-ui'
import store from './store'
const env = require('../../env')

const defIp = env.API_BASE_URL + '/'
const axios = {}
const instanceAxios = Axios.create()
const CancelToken = Axios.CancelToken;/** CancelToken 是 axios自带的取消请求函数 **/
/** 声明一个对象 用于存储每个请求的标识 和 取消函数 */
const pending = new Map()

/**
 * 添加请求
 * @param {Object} config 
 */
 const addPending = (config) => {
 /** 加上参数识别，同个接口可能请求多次的情况 **/
  // const url = [ config.method, config.url, qs.stringify(config.params), qs.stringify(config.data) ].join('&')
  const url= config.url 
  config.cancelToken = new CancelToken((cancel)=>{
    pending.set(url, cancel)
 });
}

/**
 * 移除请求
 * @param {Object} config 
 */
 const removePending = (config) => {
  // const url = [ config.method, config.url, qs.stringify(config.params), qs.stringify(config.data) ].join('&')
  const url= config.url 
  /** 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除 */
  if (pending.has(url)) {
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
}

const Info = {
  state: true,
  time: (code, val, fun) => {
    if (Info.state) {
      Info.state = false
      cok.delAll()
      MessageBox.alert('异常信息： 请重新登录。', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: action => {
          fun()
          Info.state = true
        }
      })
    }
  },
}
const globalMessage = {
  shake: {
    state: false,
    text: '',
    textList: [],
  },
  info: ({ title, desc }) => {
    if (Info.state) {
      Notification.info({
        title: title,
        message: desc,
        duration: 3000,
      })
    }
  },
  error: ({ title, desc }) => {
    if (Info.state) {
      Notification.error({
        title: title,
        message: desc,
        duration: 5000,
      })
    }
  },
  warning: ({ title, desc }) => {
    if (Info.state) {
      Notification.warning({
        title: title,
        message: desc,
        duration: 5000,
      })
    }
  },
  success: ({ title, desc }) => {
    if (Info.state) {
      Notification.success({
        title: title,
        message: desc,
        duration: 3000,
      })
    }
  },
}
const showGlobalMessage = type => {
  return (obj) => {
    let text = `${obj.desc}`
    obj.title = obj.title == 1024 ? '提示' : obj.title
    if (!globalMessage.shake.state) {
      globalMessage.shake.text = text
      globalMessage.shake.state = true
      setTimeout(() => {
        globalMessage.shake.state = false
      }, 3000)
      globalMessage[type](obj)
      globalMessage.shake.textList = []
    } else if (text != globalMessage.shake.text) {
      if (globalMessage.shake.textList.indexOf(text) == -1) {
        globalMessage.shake.textList.push(text)
        globalMessage[type](obj)
      }
    }
  }
}
const cok = {
  publicName: 'urkew_medicine_',
  set: (name, value) => {
    window.localStorage.setItem(`${cok.publicName}${name}`, JSON.stringify(value))
    return true
  },
  get: name => {
    try {
      return JSON.parse(window.localStorage.getItem(`${cok.publicName}${name}`))
    } catch (e) {
      return false
    }
  },
  del: name => {
    window.localStorage.removeItem(`${cok.publicName}${name}`)
    return true
  },
  delAll: () => {
    const localStorage = window.localStorage
    for(const key in localStorage){ //每个路径下的表头设置不清除
      if(!key.includes('medicine-menu/')){
       localStorage.removeItem(key)
      }
    }
  },
  setList: value => {
    value.forEach(x => {
      cok.set(x.name, x.value)
    })
    return true
  },
  getList: name => {

  },
}
Axios.defaults.withCredentials = true
instanceAxios.interceptors.request.use(
  function(config) {
    let storeUserId = store.getters.get_user.id
    let cookieUserId = axios.cok.get('user') && axios.cok.get('user').id

    if (storeUserId && cookieUserId && storeUserId !== cookieUserId) {
      store.commit('back_login', '重复登录比对退出')
      return Promise.reject(new Error('请勿重复登录'))
    }
    let data = config.params
    for (let i in data) {
      if (data[i] === null || data[i] == undefined || data[i] === '') delete data[i]
    }
    // blob 文件流下载
    if (config.headers['content-disposition']) {
      let name = config.headers['content-disposition'].split('=')[1]
      name = decodeURI(name)
      const obj = {
        name: name,
        type: config.headers['content-type'],
      }
      axios.cok.set('disposition', obj)
    }

    //目前只处理AI智能生成文本的请求
    if(config.headers.cancel=='cancel'){
      /** 在请求开始前，对之前的请求做检查取消操作 */
      removePending(config)
      /** 将当前请求添加到 pending 中 */
      addPending(config)     
  }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)
// 第一部分
instanceAxios.interceptors.response.use(
  response => {
    /** 在请求结束后，移除本次请求 */
    removePending(response.config)
    if (response.data.code == 101 || response.data.code == 502) {
      Info.time(response.data.code, response.data.message, () => {
        store.commit('back_login', { name: '101 or 502', obj: response })
      })
    } else if (response.data.code == 102) {
      Info.time(response.data.code, response.data.message, () => {
        store.commit('back_login', { name: '102', obj: response })
      })
    }
    return response.data

  },
  error => {
    if (error && error.response && error.response.status == 502) {
      store.commit('back_login', { name: '系统爆炸', obj: error })
    }
    removePending(error.message)

    if (Axios.isCancel(error)) {
      console.log('repeated request: ' + error.message)
      error.message='canceled'
    }
    return Promise.reject(error)
  }
)
const http = {
  postForm(action, params, info) {
    for (let i in params) {
      if (params[i] === null || params[i] == undefined) {
        delete params[i]
      }
    }
    return instanceAxios.post(action, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret
                            += encodeURIComponent(it)
                            + '='
                            + encodeURIComponent(data[it])
                            + '&'
          }
          return ret
        },
      ],
    }).then(function(response) {
      return response
    })
      .catch(function(response) {
        return response
      })
  },
  postFile(action, params, info, onProgress) {
    return instanceAxios.post(action, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (val) => {
        onProgress(val)
      },
    }).then(function(response) {
      return response
    }).catch(function(response) {
      return response
    })
  },
  postJson(action, params, info,cancel) {
    let headers={}
    if(cancel=='cancel'){
      headers={
        headers: {
          'cancel': 'cancel',
        },
       
      }
    }

    return instanceAxios.post(action, params, headers)
      .then(function(response) {
        return response
      })
      .catch(function(response) {
        return response
      })
  },
  get(action, params) {
    let data = params
    if (data == undefined) {
      data = {
        ts_new: new Date(),
      }
    } else {
      for (let i in data) {
        if (typeof data[i] === 'string' && data[i].replace(/(^\s*)|(\s*$)/g, '') === '') delete data[i]
      }
      data = Object.assign(data, { ts_new: new Date() })
    }
    return instanceAxios.get(action, { params: data })
      .then(function(response) {
        return response
      })
      .catch(function(response) {
        return response
      })
  },
  getFileBlob(action, params, info) {
    const data = params
    return instanceAxios
      .get(action, { params: data, responseType: 'blob' })
      .then(function(response) {
        const funDown = (data, fileDisposition) => {
          if (fileDisposition.type.indexOf('excel') !== -1) fileDisposition.type = 'application/x-xls'
          if (fileDisposition.type.indexOf('pdf') !== -1) fileDisposition.type = 'application/pdf'
          const blob = new Blob([data], { type: fileDisposition.type })
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileDisposition.name)
          } else {
            let link = document.createElement('a')
            link.target = '_blank'
            link.href = window.URL.createObjectURL(blob)
            link.download = fileDisposition.name
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(link.href)
          }
        }
        const getFileDisposition = axios.cok.get('disposition')
        if (response.type == 'application/json') {
          const fileReader = new FileReader()
          fileReader.onload = () => {
            try {
              let jsonData = JSON.parse(this.result)
              globalMessage.info({ title: '异常', desc: `文件下载异常!${jsonData.message}` })
            } catch (err) {
            }
          }
          fileReader.readAsText(response)
          return { code: 1024 }
        } else if (getFileDisposition === false) {
          globalMessage.info({ title: '异常', desc: '文件下载异常!' })
          return { code: 1024 }
        } else if (!response.response) {
          funDown(response, getFileDisposition)
          return { code: 0 }
        } else {
          globalMessage.info({ title: '异常', desc: `文件下载异常!${jsonData.message}` })
          return { code: 1024 }
        }
      })
      .catch(function(response) {
        return response
      })
  },
}
// 异步回掉
const ajax = (type) => {
  return (action, params, info, onProgress) => {
    return new Promise((resolve, reject) => {
      cok.set('system_time', new Date().getTime())
      http[type](defIp + action, params, info, onProgress).then(response => {
        if (response) {
          if(response.message=='canceled'){//中断上次请求
            reject('canceled')
          } else if (response.code == 0) {
            resolve(response)
            if (info && response.data !== undefined && typeof response.data === 'string') {
              globalMessage.info({ title: '提示', desc: response.data })
            } else if (info) {
              globalMessage.success({ title: '成功', desc: info })
            }
          } else if (response.code == 1000) {
            reject(response)
          } else if (response.code == 1002) {
            showGlobalMessage('warning')({ title: '提示', desc: response.message })
            reject(response)
          } else if ([101, 102, 502].includes(response.code)) {
            reject(response)
          } else {
            if (info) {
              globalMessage.error({ title: info, desc: response.message })
            } else if (info !== false) {
              if (response.code) {
                showGlobalMessage('warning')({ title: response.code, desc: response.message })
              } else {
                showGlobalMessage('warning')({ title: '提示', desc: '接口请求异常！' })
              }
            }
            reject(response)
          }
        } else {
          reject(response)
        }
      }).catch(response => {
        reject(response)
      })
    })
  }
}


axios.getFile = url => {
  const a = document.createElement('a')
  a.setAttribute('href', `${defIp}${url}`)
  a.setAttribute('target', 'testNewWindow')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
axios.seeFile = url => {
  window.open(`${defIp}${url}`)
}
axios.prefix = defIp
axios.copy = (val) => {
  if (val !== undefined && val) {
    const _input = document.createElement('input')
    // 设置内容
    _input.value = val
    // 添加临时实例
    document.body.appendChild(_input)
    // 选择实例内容
    _input.select()
    // 执行复制
    document.execCommand('Copy')
    // 删除临时实例
    document.body.removeChild(_input)
    axios.msg.success({ title: '成功', desc: '复制成功' })
  } else {
    axios.msg.warning({ title: '警告', desc: '没有内容可复制' })
  }
}
axios.get = ajax('get')
axios.getFileBlob = ajax('getFileBlob')
axios.post = ajax('postJson')
axios.postJson = ajax('postJson')
axios.postFile = ajax('postFile')
axios.postForm = ajax('postForm')

axios.cok = cok

axios.msg = {
  info: showGlobalMessage('info'),
  error: showGlobalMessage('error'),
  warning: showGlobalMessage('warning'),
  success: showGlobalMessage('success'),
  exitLogin: (text) => {
    Info.time('提示', text, () => {
      store.commit('back_login', '代码退出登录')
    })
  },
}

export default axios
