import { render, staticRenderFns } from "./ColumnSetting.vue?vue&type=template&id=80f4b0f2&scoped=true"
import script from "./ColumnSetting.vue?vue&type=script&lang=js"
export * from "./ColumnSetting.vue?vue&type=script&lang=js"
import style0 from "./ColumnSetting.vue?vue&type=style&index=0&id=80f4b0f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f4b0f2",
  null
  
)

export default component.exports