<template>
  <div class="bottom-fixed-div">
    <el-button class="button-left-8" @click="$emit('close')">
      {{ closeName }}
    </el-button>
    <el-button type="primary" class="button-left-8" @click="$emit('submit')">
      {{ submitName }}
    </el-button>
  </div>
</template>
<script>
export default {
  name: 'ZFooter',
  props: {
    submitName: {
      type: [Number, String],
      default: '提 交',
    },
    closeName: {
      type: [Number, String],
      default: '关闭',
    },
  },
}
</script>
