<template>
  <router-view id="app" />
</template>
<script>
import NP from 'number-precision'
export default {
  data() {
    return {
      hasDialog: false,
    }
  },
  created() {
    if (NP.divide(new Date().getTime() - this.$Http.cok.get('system_time'), 1000) > 7200) {
      this.$router.replace('/login')
    }
  },
  mounted() {
    window.addEventListener('error', (eventErr) => {
      const filename = eventErr.filename || ''
      if (/(.css|.js)$/.test(filename)) {
        if (this.hasDialog) {
          return
        }
        this.hasDialog = true
        this.$alert('因版本更新，页面需重新载入以加载对应资源！', '版本更新', {
          confirmButtonText: '更新',
          callback: () => {
            window.location.reload(true)
          },
        })
      }
      eventErr.preventDefault()
    }, true)
  },
}
</script>
<style>
.el-table-column--selection .cell{
  padding-left: 10px !important;

}
.el-table-column--selection .cell .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: transparent !important;
}
.el-table__row .el-table__cell .cell{
  user-select: text !important;
}
</style>
