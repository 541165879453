<template>
  <div v-if="state" :class="`z-alert z-alert-${getType.type}`">
    <div :class="`z-alert-title${showIcon ? '' : '-max'}`">
      <i  v-if="showIcon" :class="`el-icon-${getType.icon} title`" />
      <span v-if="$slots.title" class="text"><slot name="title"></slot></span>
      <span v-else class="text">{{ title }}</span>
      <i v-if="close" class="el-alert__closebtn el-icon-close" @click="state = false" />
    </div>
    <div class="z-alert-body">
      {{ info }}
      <slot name="desc"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZAlert',
  props: {
    title: {
      type: String,
      default: '提示',
    },
    info: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
    close: {
      type: Boolean,
      default: false,
    },
    showIcon: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      state: true,
    }
  },
  computed: {
    getType() {
      switch (this.type) {
      case 'info':
        return { icon: 'info', type: 'info' }
      case 'warning':
        return { icon: 'warning-outline', type: 'warning' }
      case 'success':
        return { icon: 'circle-check', type: 'success' }
      case 'error':
        return { icon: 'circle-close', type: 'error' }
      default:
        return { icon: 'info', type: 'info' }
      }
    },
  },
  methods: {
    show() {
      this.state = true
    },
  },
}
</script>
<style lang="scss">
.z-alert {
  width: 100%;
  //margin: 20px 0;
  padding: 12px 20px;
  //height: 55px;
  border-radius: 3px;

  .z-alert-title-max {
    position: relative;
    margin: 0;
    line-height: 20px;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 28px;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }

    .text {
      color: #666;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .z-alert-title {
    position: relative;
    margin: 0 0 6px 0;
    padding: 0 0 0 34px;
    line-height: 20px;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 28px;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }

    .text {
      color: #333;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .z-alert-body {
    padding: 0 20px 0 34px;
    color: #666;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.z-alert-info {
  background: #edf5ff;
  border: 1px solid #97c3ff;

  .title {
    color: #1677ff;
  }
}

.z-alert-error {
  background: #fff0ef;
  border: 1px solid #ffaba6;

  .title {
    color: #ff3b30;
  }
}

.z-alert-warning {
  background: #fffaeb;
  border: 1px solid #ffe28d;

  .title {
    color: #ffbf00;
  }
}

.z-alert-success {
  background: #eaf9f1;
  border: 1px solid #2ebf73;
  .title {
    color: #2ebf73;
  }
}
</style>
