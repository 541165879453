<template>
  <el-form
    ref="searchForm"
    :model="searchValue.data"
    label-position="left"
    :size="size"
    class="z-search"
  >
    <el-row :gutter="24" type="flex" class="formFlex">
      <slot v-if="customCol > 0" name="customCol"/>
      <el-col
        v-for="(item) in getTitle"
        :key="item.prop"
        :md="8"
        :lg="6"
        :xl="4"
        :sm="12"
        :xs="12"
      >
        <Pop
          v-model="searchValue.data[item.prop]"
          :last-value.sync="searchValue.data[item.endProp]"
          width="100%"
          :label-width="labelWidth"
          :key-text="`pop-${item.prop}`"
          :item="item"
          @submit="submit()"
        />
      </el-col>
      <el-col
        :md="8"
        :lg="6"
        :xl="4"
        :sm="12"
        :xs="12"
      >
        <el-form-item :label="config.title" :label-width="labelWidth" style="overflow: hidden;width: 100%;">
          <el-radio-group
            v-if="config.list.length != 0"
            v-model="configValue"
            @change="configChange"
          >
            <el-radio v-for="item in config.list" :key="item.value" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
          <div class="search_flex">
            <span v-if="getTitlteShow" class="pointer" @click="openSelect = !openSelect">
              {{ openSelect ? '展开' : '收起' }}
              <i :class="openSelect ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"/>
            </span>
            <el-button @click="rest()" icon="el-icon-refresh" class="button-left-8">
              重置
            </el-button>
            <el-button
              type="primary"
              class="button-left-8"
              icon="el-icon-search"
              @click="submit()"
            >
              查询
            </el-button>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
/* eslint-disable */
import Pop from './pop.vue'

export default {
  name: 'ZSearch',
  components: {
    Pop
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },
    labelWidth: {
      type: [String, Number],
      default: '96',
    },
    config: {
      type: [Array, Object],
      default: () => {
        return {
          title: '',
          list: [],
          value: 0,
        }
      },
    },
    width: {
      type: Number,
      default: 210,
    },
    customCol: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'small',
    },
    state: {
      type: Boolean,
      default: true,
    },
    // 是否开启显示动态过滤
    titleFiler: {
      type: Boolean,
      default: false,
    },
    // 是否开启角色自身字段显示动态过滤
    titleIFiler: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      restList: {},
      offShow: [],
      getTitlteShow: false,
      openSelect: true,
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    getTitleList() {
      if (this.searchValue.title == undefined) return []
      return this.searchValue.title
    },
    getTitle() {
      const titleFail = this.value.titleFail !== undefined ? this.value.titleFail : []
      const minShowSize = 3 - this.customCol
      if (this.titleFiler) {
        if (this.titleIFiler) {
          const {userSourceType} = this.store.getters.get_user
          switch (userSourceType) {
            case 2:
              titleFail.push('customerShortName', 'customerName')
              break //药企
            case 3:
              titleFail.push('customerShortName', 'customerName')
              break //药企
            case 1:
              titleFail.push('channelMerchantShortName', 'channelMerchantName')
              break //服务商
            case 6:
              titleFail.push('channelMerchantShortName', 'channelMerchantName')
              break //服务商
            case 4:
              titleFail.push('agentEnterpriseNumber', 'agentName', 'customerShortName', 'customerName')
              break //团队
          }
        }
      }
      let list = []
      if (titleFail.length === 0 || titleFail == undefined) {
        list = this.getTitleList
      } else {
        this.getTitleList.forEach(x => {
          if (titleFail.indexOf(x.prop) === -1 && x.disabled !== true) list.push(x)
        })
      }
      this.getTitlteShow = list.length > minShowSize
      return list.filter((item, i) => {
        return this.openSelect ? i < minShowSize : true
      })
    },
  },
  created() {
    this.configValue = this.config.value
  },
  mounted() {
    this.restList = Object.assign({}, this.value.data)
  },
  methods: {
    submit() {
      if (this.parent === false) {
        this.$emit('submit', true)
      } else {
        this.$parent.search(true)
      }
    },
    configChange() {
      this.$emit('configChange', this.configValue)
    },
    off(list) {
      // this.offShow = list
    },
    rest() {
      let list = this.restList
      for (let i in list) {
        if (list[i] !== null) {
          if (typeof list[i] === 'string') {
            list[i] = null
          } else if (Array.isArray(list[i])) {
            list[i] = []
          } else if (typeof list[i] === 'object') {
            list[i] = {}
          }
        }
      }
      this.searchValue.data = JSON.parse(JSON.stringify(list))
      this.$refs.searchForm.resetFields()
      this.$emit('restSuccess')
    },
  },
}
</script>
<style lang="scss">
.z-search {
  .pointer {
    color: #606266;
    margin-left: 12px;
  }
}
.search_flex {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: auto;
  justify-content: flex-end;
}

.search_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: auto;
  justify-content: flex-start;

  &_item {
    width: 220px;
    margin-right: 1%;
  }
}

.el-row--flex {
  flex-wrap: wrap;
}

.formFlex:last-child > div:last-child {
  margin-left: auto;
}
</style>
